import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";

import SellersSidebar from "../SellersComponent/SellerSideBar.jsx";

import { axiosInstance } from "../../../../components/baseUrl.jsx";

import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import toast, { Toaster } from "react-hot-toast";

import cookies from "js-cookie";

import {
  Capitalize,
  numberWithCommas,
} from "../../../../components/helpers/functionHelpers.js";
import { BigCard } from "../../../../components/SkeletonLoader/BigCard.js";
import { CurrencyExchangeContext } from "../../../../components/utils/CurrencyExchangeState.js";

const SellersProductDescription = () => {
  const { t } = useTranslation();
  const { convertCurrency } = useContext(CurrencyExchangeContext);
  const [productInfo, setProductInfo] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [currentImage, setCurrentImage] = useState({});
  const [loading, setLoading] = useState(true);
  const { productId } = useParams();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const selectedCurrency = cookies.get("currency") || "NGN";

  const displayImageHandler = (imageIndex) => {
    setCurrentImage(productImages[imageIndex].image);
  };

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const submit = (productID) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure you want to delete this product?",
      buttons: [
        {
          label: "Yes",
          onClick: (e) => handleDelete(productID),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const handleDelete = (productId) => {
    axiosInstance
      .delete(`/product/${productId}`)
      .then((response) => {
        toast.success("Product deleted successfully.", {
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });

        setTimeout(() => {
          navigate(-1);
        }, 4800);
      })
      .catch((error) => {
        console.log(error);
        if (!error.response.data.errors) {
          return navigate(`/no-connection`);
        }
        toast.error(`${error.response.data.errors[0].message}`, {
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });
      });
  };

  const getInfo = async () => {
    try {
      const { data } = await axiosInstance.get(`/product/${productId}`);
      setProductInfo(data.data);
      setProductImages(data.data.productImages);
      const mainImage = data.data.productImages[0].image;
      setCurrentImage(mainImage);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.message && error.message === "Network Error") {
        navigate("/no-connection");
      }
    }
  };
  console.log(productInfo);

  useEffect(() => {
    getInfo();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, navigate]);

  return (
    <>
      <Toaster />
      <div>
        <div className="grid-container">
          <div className={"menuu-icon"} onClick={handleClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-6 h-6"
            >
              <path
                fillRule="evenodd"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                clipRule="evenodd"
              />
            </svg>
          </div>

          <SellersSidebar isActive={isActive} handleClick={handleClick} />

          {loading ? (
            <BigCard />
          ) : (
            <main className="main">
              <h2>
                {productInfo.productName && Capitalize(productInfo.productName)}
              </h2>
              <div className="button-ctn">
                <div className="edit-btn-ctn">
                  <Link to={`/edit-product/${productInfo.id}`}>
                    <button>{t("Edit_product")}</button>
                  </Link>
                </div>
                <div className="delete-btn-ctn">
                  <button onClick={(e) => submit(productInfo.id)}>
                    {t("Delete_product")}
                  </button>
                </div>
              </div>
              <div className="main-overview">
                <div className="overview-card ">
                  <div className=" prod-descrption-ctn">
                    <div className="prod-description-img-wrapper">
                      <div className="prod-description-img-ctn">
                        <div className="featured-img-ctn">
                          <img src={currentImage} alt="featured" />
                        </div>
                        <div className="other-img-ctn">
                          {productImages &&
                            productImages.map((image, index) => (
                              <div
                                className="img-box"
                                onClick={() => displayImageHandler(index)}
                                key={index}
                              >
                                <img
                                  src={image.image}
                                  key={index}
                                  alt="product"
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="product-info-wrapper">
                      <div className="product-info-ctn">
                        <h3 className="prod-title">
                          {t("Product_Information")}
                        </h3>
                        <div className="product-info-content">
                          <div className="prod-info">
                            <p className="type">{t("Product_Status")}</p>
                            <p className="value">
                              {productInfo && productInfo.productStatus}
                            </p>
                          </div>
                          <div className="prod-info">
                            <p className="type">Price</p>
                            <p className="value">
                              {selectedCurrency}{" "}
                              {productInfo.minPricePerUnit &&
                                numberWithCommas(
                                  convertCurrency(
                                    productInfo?.minPricePerUnit,
                                    productInfo?.currencyType,
                                    selectedCurrency
                                  )
                                )}
                            </p>
                          </div>
                          <div className="prod-info">
                            <p className="type">MOQ</p>
                            <p className="value">
                              {productInfo?.minOrdersAllowed
                                ? productInfo.minOrdersAllowed
                                : "1"}
                            </p>
                          </div>
                          <div className="prod-info">
                            <p className="type">{t("Country_of_origin")}</p>
                            <p className="value">
                              {productInfo && productInfo.countryOfOrigin}
                            </p>
                          </div>
                          <div className="prod-info">
                            <p className="type">{t("Lead_Time[Min]")}</p>
                            <p className="value">
                              {" "}
                              {productInfo && productInfo.minDuration}
                            </p>
                          </div>
                          <div className="prod-info">
                            <p className="type">{t("Lead_Time[Max]")}</p>
                            <p className="value">
                              {productInfo && productInfo.maxDuration}
                            </p>
                          </div>
                          <div className="prod-info">
                            <p className="type">{t("Supply_capacity")}</p>
                            <p className="value">
                              {productInfo && productInfo.supplyCapacity}{" "}
                              {productInfo && productInfo.unitForSupplyCapacity}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="product-info-wrapper second-wrapper">
                      <div className="product-info-ctn">
                        <h3 className="prod-title">
                          {t("Available_Specification")}
                        </h3>
                        <div className="product-info-content">
                          {productInfo.productSpecification &&
                            Object.entries(
                              productInfo.productSpecification
                            ).map((specification, index) => (
                              <div className="prod-info" key={index}>
                                <p className="type">
                                  {" "}
                                  {Capitalize(specification[0])}
                                </p>
                                <p className="value">
                                  {Capitalize(specification[1])}
                                </p>
                              </div>
                            ))}
                          <div className="prod-info">
                            <p className="type">{t("Category")}</p>
                            <p className="value">
                              {productInfo &&
                                productInfo?.productCategories?.map(
                                  (category) => (
                                    <>{category.category.category}, </>
                                  )
                                )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="prod-des-wrapper">
                    <div className="prod-des-title-ctn">
                      <h3>{t("Product_Description")}</h3>
                    </div>
                    <div className="prod-des-paragraph-ctn">
                      <p>{productInfo.productDescription}</p>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          )}
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(SellersProductDescription, ["SELLER"]);
