import { useEffect, useMemo, useState } from "react";
import { axiosInstance } from "../baseUrl";

export const useGetMoMoReferralCode = () => {
  const [allReferral, setAllReferral] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 10;

  const getAllReferral = async () => {
    setLoading(true);
    try {
      const data = await axiosInstance.get(`/referer/all`);
      setAllReferral(data?.data);
      setLoading(false);
    } catch (error) {
      console.log("error getting all referral", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllReferral();
  }, []);

  const referralData = useMemo(() => {
    let computedReferral = allReferral;
    if (search) {
      computedReferral = computedReferral.filter((referral) =>
        referral.refererCode.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedReferral.length);

    return computedReferral.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [allReferral, currentPage, search]);

  return {
    allReferral,
    currentPage,
    search,
    referralData,
    setCurrentPage,
    setSearch,
    totalItems,
    loading,
    ITEMS_PER_PAGE,
  };
};
