import React from "react";

import "./MoMoPaymentModal.css";

import { Modal, Input, Button, Spin } from "antd";

import MoMoLogo from "../../assets/img/momo.png";
import { numberWithCommas } from "../helpers/functionHelpers";

const MoMoPaymentModal = ({
  loadMoMo,
  handleCancelMoMoPaymentModal,
  openMoMoPaymentModal,
  handleSubmitMoMoPayment,
  handleMoMoPaymentDetailsChange,
  momoPaymentDetails,
  currency,
  amount,
  formatCountdown,
  handleConfirmMoMoPayment,
  isCodeStep,
  countdown,
  confirmLoadMoMo,
  user,
}) => {
  return (
    <Modal
      title=""
      open={openMoMoPaymentModal}
      onCancel={handleCancelMoMoPaymentModal}
      footer={null}
      maskClosable={false}
    >
      <div className="payment-modal-content">
        <img src={MoMoLogo} alt="Company Logo 1" className="company-logo" />
        <h2 className="amount">
          Amount: {currency}
          {numberWithCommas(amount())}
        </h2>
        {!isCodeStep ? (
          <>
            <div className="momo__phone">
              <h6>MoMo phone number:</h6>
              <p>{user?.phoneNumber && user?.phoneNumber}</p>
            </div>

            <Input
              placeholder="Enter referral code (optional)"
              name="referralCode"
              value={momoPaymentDetails.referralCode}
              onChange={handleMoMoPaymentDetailsChange}
              className="phone-input"
            />
            <Input.TextArea
              placeholder="Add a note (optional)"
              name="note"
              value={momoPaymentDetails.note}
              onChange={handleMoMoPaymentDetailsChange}
              className="note-input"
            />
            {loadMoMo ? (
              <Button type="primary" block>
                <Spin />
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => handleSubmitMoMoPayment()}
                block
              >
                Pay
              </Button>
            )}
          </>
        ) : (
          <>
            <p className="momo-note">
              Please initiate payment on your MoMo app. You have{" "}
              <span>{formatCountdown(countdown)}</span> remaining.
            </p>
            <p className="momo-note">
              Once you've intiated payment, click the button below to proceed.
            </p>
            {confirmLoadMoMo ? (
              <Button type="primary" block>
                <Spin />
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() =>
                  handleConfirmMoMoPayment(momoPaymentDetails.referralCode)
                }
                block
              >
                Confirm payment
              </Button>
            )}
          </>
        )}
        <span className="text-danger">
          Do not close until you have completed payment
        </span>
      </div>
    </Modal>
  );
};

export default MoMoPaymentModal;
