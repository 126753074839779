import { useContext, useState } from "react";

import toast from "react-hot-toast";

import { axiosInstance } from "../baseUrl";
import { useEventTag } from "./useEventTag";
import { ensurePlus, toastOptions } from "../helpers/functionHelpers";
import { GlobalContext } from "../utils/GlobalState";

export const useRegisterMoMoUser = () => {
  const [loadUser, setLoadUser] = useState();
  const { handleEventTag } = useEventTag();
  const { setUser } = useContext(GlobalContext);

  const handleRegisterMoMoUser = async (phoneNumber) => {
    setLoadUser(true);
    try {
      const userDetails = {
        firstName: "",
        LastName: "",
        email: "",
        phoneNumber: ensurePlus(phoneNumber),
        role: "SELLER",
        password: "",
        hearAboutUs: "OTHERS",
      };
      const {
        data: { data },
      } = await axiosInstance.post(`/ipg/user`, userDetails);
      setUser(data.user);
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", true);
      setLoadUser(false);
    } catch (error) {
      console.log("error", error);
      setLoadUser(false);
      if (error?.response?.data?.errors[0].message) {
        toast.error(error?.response?.data?.errors[0].message, toastOptions);
        handleEventTag(
          `e_momo_userReg_${error.response.data.errors[0].message}`
        );
      } else {
        handleEventTag(`e_momo_userReg`);
        toast.error(`Network error!`, toastOptions);
      }
    }
  };
  return {
    handleRegisterMoMoUser,
    loadUser,
  };
};
