import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../baseUrl";
import toast from "react-hot-toast";
import { toastOptions } from "../helpers/functionHelpers";

export const useEditUserProfile = () => {
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [user, setUser] = useState("");
  const [rerenderr, setRerenderr] = useState(true);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const [isChange, setIsChange] = useState(false);
  const [locationDetails, setLocationDetails] = useState({
    streetNo: "",
    home: "",
    street: "",
    city: "",
    state: "",
    country: "",
    countyShort: "",
    zipCode: "",
  });

  const navigate = useNavigate();

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const getUser = () => {
    setUserLoading(true);
    axiosInstance
      .get(`/auth/current-user`)
      .then((response) => {
        setUser(response.data?.currentUser);
        if (response.data?.currentUser?.address) {
          setAddress(response.data?.currentUser?.address);
        }
        if (response?.data?.currentUser?.phoneNumber) {
          setPhoneNumber(response?.data?.currentUser?.phoneNumber);
        }
        if (response?.data?.currentUser?.country) {
          setCountry(response?.data?.currentUser?.country);
        }
        if (response?.data?.currentUser?.email) {
          setEmail(response?.data?.currentUser?.email);
        }
        if (response?.data?.currentUser?.firstName) {
          setFirstName(response?.data?.currentUser?.firstName);
        }
        if (response?.data?.currentUser?.LastName) {
          setLastName(response?.data?.currentUser?.LastName);
        }
        setUserLoading(false);
      })
      .catch((error) => {
        setUser(null);
        setUserLoading(false);
        localStorage.setItem("user", false);
      });
  };

  useEffect(() => {
    getUser();
  }, [rerenderr]);

  const handleEditProfile = async (e) => {
    e.preventDefault();
    setUpdateLoader(true);
    if (!address) {
      toast.error(`Please enter an address.`, toastOptions);
      return;
    }

    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
    );
    const data = await response.json();

    if (data.status !== "OK") {
      toast.error(
        `Invalid address. Please enter a valid address from the suggestions.`,
        toastOptions
      );
      return;
    }
    const addressComponents = data.results[0].address_components;

    const locationDetails = {
      streetNo: "",
      home: "",
      street: "",
      city: "",
      state: "",
      country: "",
      countyShort: "",
      zipCode: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;
      if (types.includes("street_number")) {
        locationDetails.streetNo = component.long_name;
      }
      if (types.includes("route")) {
        locationDetails.home = component.long_name;
      }
      if (types.includes("neighborhood")) {
        locationDetails.street = component.long_name;
      }
      if (types.includes("administrative_area_level_2")) {
        locationDetails.city = component.long_name;
      }
      if (types.includes("administrative_area_level_1")) {
        locationDetails.state = component.long_name;
      }
      if (types.includes("country")) {
        locationDetails.country = component.long_name;
      }
      if (types.includes("country")) {
        locationDetails.countyShort = component.short_name;
      }
      if (types.includes("postal_code")) {
        locationDetails.zipCode = component.long_name;
      }
    });
    setLocationDetails(locationDetails);

    try {
      const editUserDetails = {
        country,
        address,
        email,
        firstName,
        LastName: lastName,
        phoneNumber,
      };
      await axiosInstance.post("/ipg/user/update", editUserDetails);
      setUpdateLoader(false);
      toast.success("Your profile has been successful updated.", toastOptions);

      setRerenderr((prev) => !prev);
      setIsChange(false);
    } catch (error) {
      console.log(error);
      setUpdateLoader(false);
      if (!error.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${error.response.data.errors[0].message}`, toastOptions);
    }
  };
  return {
    user,
    handleEditProfile,
    updateLoader,
    handleAddressChange,
    handleCountryChange,
    address,
    country,
    setAddress,
    userLoading,
    isChange,
    setIsChange,
    email,
    setEmail,
    lastName,
    setLastName,
    firstName,
    setFirstName,
    locationDetails,
    setLocationDetails,
    rerenderr,
  };
};
