import React, { useState } from "react";
import Sidebar from "../../../components/MoMoComponent/Sidebar";
import MoMoHeader from "../../../components/MoMoComponent/MoMoHeader";

const Overview = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive((current) => !current);
  };
  return (
    <div>
      <div className="grid-container">
        <MoMoHeader title="Overview" handleClick={handleClick} />
        <Sidebar isActive={isActive} handleClick={handleClick} />
        <main className="main">
          <div className="main-overview">
            <div className="overview-card">
              <div>
                <h2>All Referrals</h2>

                <div className="d-flex justify-content-between mt-4">
                  <h3>1000</h3>
                </div>
              </div>
            </div>
            <div className="overview-card">
              <div>
                <h2>Total Trnsactions</h2>

                <div className="d-flex justify-content-between mt-4">
                  <h3>120</h3>
                </div>
              </div>
            </div>
            <div className="overview-card">
              <div>
                <h2>Total profit</h2>

                <div className="d-flex justify-content-between mt-4">
                  <h3>$23,000</h3>
                </div>
              </div>
            </div>
          </div>

          {/* {loading ? (
        <TableSkeleton />
      ) : (
        <>
          {sellerOrders && sellerOrders.length > 0 ? (
            <div className="main-overview">
              <div className="overview-card no-padding">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">{t("Order_No")}</th>
                        <th scope="col">{t("Product_Info")}</th>
                        <th scope="col">{t("Product_Cost")}</th>
                        <th scope="col">{t("Incoterms")}</th>
                        <th scope="col">{t("Payment_Terms")}</th>
                        <th scope="col">{t("Status")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sellerOrders &&
                        sellerOrders.map((orders, index) => (
                          <tr key={index}>
                            <td>
                              {orders.orderNumber && orders.orderNumber}
                            </td>
                            <td>
                              <p>
                                <Link
                                  to={`/seller/order-info/${orders.id}`}
                                >
                                  {orders.productName
                                    ? Capitalize(orders.productName)
                                    : orders.product
                                    ? Capitalize(orders.product.productName)
                                    : " "}
                                </Link>
                              </p>
                            </td>
                            <td>
                              {selectedCurrency}{" "}
                              {orders.cost &&
                                convertPrice(orders.cost, selectedCurrency)}
                            </td>
                            <td>
                              {orders.incoterm &&
                              orders.incoterm === "LOCAL_DELIVERY"
                                ? "Local delivery"
                                : orders.incoterm}
                            </td>
                            <td>
                              {orders.paymentTerm && orders.paymentTerm}
                            </td>
                            <td>
                              {orders.status === "PENDING" && (
                                <div className="text-warning ">
                                  {t("Pending")}
                                </div>
                              )}
                              {orders.status === "PROCESSING" && (
                                <div className="text-primary ">
                                  {t("Processing")}
                                </div>
                              )}
                              {orders.status === "SHIPPED" && (
                                <div className="text-info">
                                  {t("Shipped")}
                                </div>
                              )}
                              {orders.status === "DELIVERED" && (
                                <div className="text-success">
                                  {t("Delivered")}
                                </div>
                              )}
                              {orders.status === "RECEIVED" && (
                                <div className="text-secondary">
                                  {t("Received")}
                                </div>
                              )}
                              {orders.status === "NOT RECEIVED" && (
                                <div className="text-secondary">
                                  {t("Not_Received")}
                                </div>
                              )}
                              {orders.status === "CANCELLED" && (
                                <div className="text-danger">
                                  {t("Cancelled")}
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ) : (
            <>
              {search ? (
                <div className="empty-state">
                  <h4>{t("No_results_found")}</h4>
                  <p>{t("No_order_matched")}</p>
                </div>
              ) : (
                <div className="empty-state">
                  <h3>{t("Welcome_to_your_dashboard")}</h3>
                  <p>
                    {t("Manage_your_orders")}{" "}
                    <Link to="/create-new-product">{t("here")}!</Link>
                  </p>
                </div>
              )}
            </>
          )}

          <Pagination
            current={currentPage}
            total={totalPages}
            onPageChange={handlePageChange}
            extraClassName="justify-content-start"
          />
        </>
      )} */}
        </main>
      </div>
    </div>
  );
};

export default Overview;
