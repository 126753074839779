import { useEffect, useMemo, useState } from "react";
import { axiosInstance } from "../baseUrl";

export const useGetMoMoReferralTransactions = () => {
  const [allMoMoReferralTransactions, setAllMoMoReferralTransactions] =
    useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 10;

  const getAllReferralTramsaction = async () => {
    setLoading(true);
    try {
      const data = await axiosInstance.get(`/ref-order/all`);
      setAllMoMoReferralTransactions(data?.data);
      console.log("data fetching referral", data);
    } catch (error) {
      console.log("error getting all referral", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllReferralTramsaction();
  }, [allMoMoReferralTransactions]);

  const referralData = useMemo(() => {
    let computedReferral = allMoMoReferralTransactions;

    if (search) {
      computedReferral = computedReferral.filter(
        (referral) =>
          referral?.productName?.toLowerCase().includes(search.toLowerCase()) ||
          referral.companyName.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedReferral.length);

    return computedReferral.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [allMoMoReferralTransactions, currentPage, search]);

  return {
    allMoMoReferralTransactions,
    currentPage,
    search,
    referralData,
    setCurrentPage,
    setSearch,
    totalItems,
    loading,
    ITEMS_PER_PAGE,
  };
};
