import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import ImageOne from "../../assets/img/imageFour.png";
import MainImage from "../../assets/img/becomeSeller.png";

import "./SellersLandingPage.css";

const SellersLandingPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <div>
        <div className=" bs-main-wrapper ">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12 col-12 ">
                <div className="bs-main-content">
                  <h2 className="bs-title">
                    <span>{t("Connect_and_Trade")}</span>{" "}{t("with_Suppliers")}
                  </h2>
                  <p className="bs-paragraph">
                  {t("Take_your_business")}
                  </p>
                  <a href="/registration/seller">
                    <button className="bs-button">{t("Register_Now")}</button>
                  </a>
                </div>
              </div>
              <div className="col-lg-5 col-sm-10 col-12">
                <div className="bs-main-img-ctn">
                  <img src={ImageOne} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ws-market-ctn container">
          <div className="ws-market-title-ctn">
            <h2 className="ws-market-title">{t("Why_Sell_on_The_marketplace?")}</h2>
            <p className="ws-market-paragraph">
            {t("Learn_the_basics")}
            </p>
          </div>
          <div className="ws-market-content-wrapper row">
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 ws-market-content">
              <p className="ws-market-numbers">1</p>
              <h6 className="ws-market-heading">{t("List_your_item")}</h6>
              <p className="ws-market-content-p">
                {t("You_can_list")}
              </p>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 ws-market-content">
              <p className="ws-market-numbers">2</p>
              <h6 className="ws-market-heading">{t("Get_seller_protection")}</h6>
              <p className="ws-market-content-p">
              {t("We_ensure_that")}
              </p>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 ws-market-content">
              <p className="ws-market-numbers">3</p>
              <h6 className="ws-market-heading">{t("Connect_with_more_buyers")}</h6>
              <p className="ws-market-content-p">
              {t("You_can_contact")}
              </p>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 ws-market-content">
              <p className="ws-market-numbers">4</p>
              <h6 className="ws-market-heading">{t("Promotions_and_marketing")}</h6>
              <p className="ws-market-content-p">
              {t("You_can_Benefit")}
              </p>
            </div>
          </div>
        </div>
        <div className=" bs-main-wrapper ">
          <div className="container">
            <div className="bs-step-plans-ctn">
              <div className="bs-step-ctn row">
                <div className="col-lg-5 col-md-10 col-12 ">
                  <h2 className="ws-market-title">
                  {t("Want_to_become_a_supplier?")}
                  </h2>
                  <div className="bs-step-img-ctn">
                    <img src={MainImage} alt="" />
                  </div>
                </div>
                <div className="col-lg-7 col-md-10 col-12">
                  <ul className="steps-ctn">
                    <li>
                    {t("Step_1")}:{" "}{t("Register_under_5_minutes")}:{" "}{t("fill_the_registration")}
                    </li>
                    <li>
                    {t("Step_2")}:{" "}{t("Become_an_Agro")}:{" "}{t("complete_the_dedicated")}
                    </li>
                    <li>
                    {t("Step_3")}:{" "}{t("List_your_products")}:{" "}{t("upload_products")}
                    </li>
                  </ul>
                  <div className="steps-button">
                    <a href="/registration/seller">
                      <button className="bs-button">{t("Register_Now")}</button>
                    </a>
                  </div>
                </div>
              </div>
              <div className="bs-plan-ctn">
                <div className="bs-plan-title-ctn">
                  <h2>{t("Plans_&_Pricing")}</h2>
                  <p>
                  {t("Simple_Pricing.")}
                  </p>
                </div>

                <div className="bs-plan-wrapper ">
                  <div className="bs-plan-content ">
                    <div className="bs-plan-load"></div>
                    <div className="bs-plan-type">
                      <h3>{t("Basic_Plan")}</h3>
                      <p>{t("The_perfect_Plan")}</p>
                    </div>
                    <div className="bs-plan-price">
                      <h3>{t("FREE")}</h3>
                      <p>{t("No_cost_on_this_plan")}</p>
                    </div>
                    <div className="bs-plan-button">
                      <Link to="/become-seller">
                        <button>{t("Try_This_Plan")}</button>
                      </Link>
                    </div>
                    <div className="bs-plan-terms-ctn">
                      <div className="bs-plan-terms-content">
                        <i className="fas fa-times"></i>
                        <p>{t("No_Quote_Posting")}</p>
                      </div>
                      <div className="bs-plan-terms-content">
                        <i className="fas fa-check"></i>
                        <p>{t("Commission")}: 5%</p>
                      </div>
                      <div className="bs-plan-terms-content">
                        <i className="fas fa-check"></i>
                        <p>{t("Number_of_Products")}: 5</p>
                      </div>
                      <div className="bs-plan-terms-content">
                        <i className="fas fa-check"></i>
                        <p>{t("Number_of_Pictures")}: 10</p>
                      </div>
                      <div className="bs-plan-terms-content">
                        <i className="fas fa-check"></i>
                        <p>
                        {t("Access_buyer/Seller_contact_details")}:{" "}{t("2$_Per_contract")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bs-plan-content ">
                    <div className="bs-plan-load"></div>
                    <div className="bs-plan-type">
                      <h3>{t("Premium_Plan")}</h3>
                      <p>{t("The_perfect_Plan_for_Ease")}</p>
                    </div>
                    <div className="bs-plan-price">
                      <h3>
                        $40<span>/{t("Month")}</span> - $150<span>/{t("Quarter")}</span>{" "}
                      </h3>
                      <p>{t("Billed")}</p>
                    </div>
                    <div className="bs-plan-button">
                      <Link to="/become-seller">
                        <button>{t("Try_This_Plan")}</button>
                      </Link>
                    </div>
                    <div className="bs-plan-terms-ctn">
                      <div className="bs-plan-terms-content">
                        <i className="fas fa-check"></i>
                        <p>{t("Quote_Posting")}</p>
                      </div>
                      <div className="bs-plan-terms-content">
                        <i className="fas fa-check"></i>
                        <p>{t("Commission")}: 3.5%</p>
                      </div>
                      <div className="bs-plan-terms-content">
                        <i className="fas fa-check"></i>
                        <p>{t("Number_of_Products")}:{" "}{t("Unlimited")}</p>
                      </div>
                      <div className="bs-plan-terms-content">
                        <i className="fas fa-check"></i>
                        <p>{t("Number_of_Pictures")}:{" "}{t("Unlimited")}</p>
                      </div>
                      <div className="bs-plan-terms-content">
                        <i className="fas fa-check"></i>
                        <p>{t("Access_buyer/Seller_contact_details")}:{" "}{t("FREE")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bs-plan-paragraph">
                  <p>
                    <span>{t("*A_Free_Plan")}</span>{" "}{t("is_granted")}. <br />
                    {t("All_though")}
                     <br />{" "}
                     {t("that_can_be_accessed_greatly")}.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row py-5">
            <div className="col-md-6 col-sm-12 col-12 comp-ctn">
              <h2>{t("Our_Supplier")}</h2>
              <div className="comp-wrapper">
                <div className="comp-line"></div>
                <div className="comp-content">
                  <p>
                  {t("All_compliances")}.{" "}
                  </p>
                </div>
              </div>
              <Link to="/">
                <div className="comp-bottom">
                  <div>
                    <i className="fas fa-download"></i>
                  </div>
                  <p>{t("Download_the_Supplier")}</p>
                </div>
              </Link>
            </div>
            <div className="col-md-6 col-sm-12 col-12 comp-ctn">
              <h2>{t("Our_Product_Exclusions")}</h2>
              <div className="comp-wrapper">
                <div className="comp-line"></div>
                <div className="comp-content">
                  <p>
                  {t("Certain_products_are_prohibited_from_being_listed_on_the_suppliers_marketplace._Kindly_view_the_document_of_excluded_products_in_order_to_be_made_aware_and_abstain_from_such_acts")}.
                  </p>
                </div>
              </div>
              <Link to="/">
                <div className="comp-bottom">
                  <div>
                    <i className="fas fa-download"></i>
                  </div>
                  <p>{t("Download_the_Product")}</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className=" bs-main-wrapper ">
          <div className="container">
            <div className="bs-video-ctn">
              <div className="bs-video-title-ctn">
                <h3>
                {t("Use_the_supplier’s")}{" "}<br />
                  {t("the_right_way")}
                </h3>
                <p>
                {t("Learn_the_essential_ways_to_successfully_maximize_the_marketplace_for_a_successful_trade.")}{" "}
                </p>
              </div>
              <div className="bs-video-wrapper">
                <Link to="/become-seller">
                  <p>
                    <i className="fas fa-play-circle"></i>{" "}
                    <span>{t("Play_tutorial")}</span>
                  </p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className="seller-landing-footer" />
    </>
  );
};

export default SellersLandingPage;
