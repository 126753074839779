import React, { useState, useEffect, useContext } from "react";
import "./ProductInfo.css";
import { Link, useNavigate, useParams } from "react-router-dom";

import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

import ProductSpecification from "./ProductSpecification";
import ProductDescription from "./ProductDescription";

import { axiosInstance } from "../../components/baseUrl";

import toast, { Toaster } from "react-hot-toast";

import "./InquireQuote.css";

import NewlyAddedProducts from "../../components/FetchedProducts/NewLlyAddedProduct";

import CardSkeleton from "../../components/SkeletonLoader/CardSkeleton";

import { GlobalContext } from "../../components/utils/GlobalState";
// import MessageSeller from "./MessageSeller";
import { Capitalize } from "../../components/helpers/functionHelpers";
import { useGetProductInfo } from "../../components/hooks/useGetProductInfo";
import cookies from "js-cookie";
import { convertToDollars } from "../../components/helpers/convertToDollars";
import { convertPrice } from "../../components/helpers/convertPrice";
import {
  fiveStar,
  fourStar,
  noStar,
  oneStar,
  threeStar,
  twoStar,
} from "../../components/constants";
import { useTranslation } from "react-i18next";
import { useEventTag } from "../../components/hooks/useEventTag";
import { CurrencyExchangeContext } from "../../components/utils/CurrencyExchangeState";

const ProductInfo = () => {
  const { t } = useTranslation();
  const { handleEventTag } = useEventTag();
  const [isActive, setIsActive] = useState(true);
  const [loader, setLoader] = useState(false);
  const [loadCart, setLoadCart] = useState(false);
  const selectedCurrency = cookies.get("currency") || "NGN";

  const { user, userLoading, setRerender } = useContext(GlobalContext);
  const { convertCurrency } = useContext(CurrencyExchangeContext);

  const { productId } = useParams();

  const {
    getInfo,
    displayImageHandler,
    loading,
    currentImage,
    productInfo,
    productImages,
  } = useGetProductInfo();

  const navigate = useNavigate();

  const [inquiry, setInquiry] = useState({
    productDescription: "",
    quantityRequired: "1",
    termsOfTrade: "",
    paymentTerms: "",
    destinationPort: "",
    targetPrice: "",
    unit: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  useEffect(() => {
    getInfo(productId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const addToCart = async (e) => {
    e.preventDefault();
    if (!user) {
      return navigate("/login");
    }

    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    setLoadCart(true);
    try {
      const createCart = {
        productID: productInfo.id,
        quantity: 1,
      };
      await axiosInstance.post("/cart", createCart);
      toast.success("Product added to cart", {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      setLoadCart(false);
      handleEventTag(`p_${productInfo.productName} add cart`, user.id);
      setRerender((prev) => !prev);
    } catch (error) {
      console.log(error);
      setLoadCart(false);
      if (!error.response.data.errors) {
        handleEventTag(`e_add_cart`);
        return navigate(`/login`);
      }
      toast.error(`${error.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  const handleChange = (e) => {
    setInquiry({
      ...inquiry,
      [e.target.name]: e.target.value,
    });
  };

  const handleInquirySubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      return navigate("/login");
    }
    setLoader(true);
    let toastId = "";
    toastId = toast.loading("loading...", {
      style: {
        background: "#353434",
        color: "#fff",
      },
    });
    try {
      const createInquiry = {
        productName: productInfo.productName,
        productDescription: inquiry.productDescription,
        quantityRequired: inquiry.quantityRequired,
        destinationPort: inquiry.destinationPort,
        termsOfTrade: inquiry.termsOfTrade,
        paymentTerms: inquiry.paymentTerms,
        productId: productInfo.id,
        userId: user.id,
        unit: inquiry.unit,
        targetPrice: convertToDollars(inquiry.targetPrice, selectedCurrency),
      };
      await axiosInstance.post("/rfq", createInquiry);
      setLoader(false);
      setInquiry({
        productDescription: "",
        quantityRequired: "1",
        termsOfTrade: "",
        paymentTerms: "",
        destinationPort: "",
        targetPrice: "",
        unit: "",
      });
      toast.success("You have successfully created an RFQ.", {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
      navigate("/rfq/success-message");
    } catch (err) {
      console.log(err);
      setLoader(false);
      if (!err.response.data.errors) {
        return navigate(`/no-connection`);
      }
      toast.error(`${err.response.data.errors[0].message}`, {
        id: toastId,
        duration: 4000,
        style: {
          background: "#353434",
          color: "#fff",
        },
      });
    }
  };

  if (loading || userLoading) {
    return <CardSkeleton />;
  }

  return (
    <>
      <Toaster />
      <Header />
      <div className={loadCart ? "dim-loader" : ""}></div>
      <section id="product-listing">
        <div className="container">
          <div className="row mb-2"></div>

          <div className="row mb-5">
            <div className="col-lg-5">
              <div className="mp-wrap">
                <div className="main-product-pic">
                  <img src={currentImage} className="" alt="..." />
                </div>
                <div className="product-thumbnail">
                  <div className="d-flex justify-content-start flex-wrap">
                    {productImages?.slice(0, 5).map((image, index) => (
                      <div
                        className="pt-box"
                        onClick={() => displayImageHandler(index)}
                        key={index}
                      >
                        <img src={image?.image} className="" alt="..." />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 row ">
              <div className="col-lg-6">
                <div className="product-detail-ctn">
                  <h2 className="product-name">
                    {productInfo.productName &&
                      Capitalize(productInfo.productName)}
                  </h2>
                  <p className="product-price">
                    <span className="p-currency">{selectedCurrency}</span>{" "}
                    {productInfo &&
                      convertCurrency(
                        productInfo.minPricePerUnit,
                        productInfo?.currencyType,
                        selectedCurrency
                      )}{" "}
                    <span className="p-unit">
                      / {productInfo?.unitForMinOrder}
                    </span>
                  </p>
                  <p className="product-price">
                    <span className="p-currency">MOQ:</span>{" "}
                    <span className="p-unit">
                      {productInfo?.minOrdersAllowed
                        ? productInfo.minOrdersAllowed
                        : "1"}{" "}
                      / {productInfo?.unitForMinOrder}{" "}
                    </span>
                  </p>
                  <p className="product-price">
                    <span className="p-currency">{t("Capacity")}:</span>{" "}
                    <span className="p-unit">
                      {productInfo?.unitForSupplyCapacity} / {t("Monthly")}
                    </span>
                  </p>

                  <p className="product-price ml-2">
                    <span className="p-currency">
                      {t("Delivery_duration")}:
                    </span>{" "}
                    <span className="p-unit">
                      {" "}
                      {productInfo?.minDuration} - {productInfo?.maxDuration}
                    </span>
                  </p>
                </div>
                <div className="product-specification-ctn">
                  <h2 className="product-specification-title">
                    {t("Reviews")}
                  </h2>
                  <div className="product-specification-content">
                    <div className="review-star-wrapper">
                      <div className="reviews-star-ctn">
                        {productInfo?.reviewsAverage < 1
                          ? noStar.map((star, index) => (
                              <img src={star} alt="star" key={index} />
                            ))
                          : productInfo?.reviewsAverage < 2 &&
                            productInfo?.reviewsAverage > 0
                          ? oneStar.map((star, index) => (
                              <img src={star} alt="star" key={index} />
                            ))
                          : productInfo?.reviewsAverage < 3 &&
                            productInfo?.reviewsAverage > 1
                          ? twoStar.map((star, index) => (
                              <img src={star} alt="star" key={index} />
                            ))
                          : productInfo?.reviewsAverage < 4 &&
                            productInfo?.reviewsAverage > 2
                          ? threeStar.map((star, index) => (
                              <img src={star} alt="star" key={index} />
                            ))
                          : productInfo?.reviewsAverage < 5 &&
                            productInfo?.reviewsAverage > 3
                          ? fourStar.map((star, index) => (
                              <img src={star} alt="star" key={index} />
                            ))
                          : productInfo?.reviewsAverage < 6 &&
                            productInfo?.reviewsAverage > 4
                          ? fiveStar.map((star, index) => (
                              <img src={star} alt="star" key={index} />
                            ))
                          : null}
                      </div>

                      <Link
                        to={`/product/review/${productInfo.id}`}
                        className="product-specification-type"
                      >
                        (
                        {productInfo?.reviewsCount && productInfo?.reviewsCount}{" "}
                        {productInfo?.reviewsCount &&
                        productInfo?.reviewsCount <= 1
                          ? "review"
                          : productInfo?.reviewsCount === 0
                          ? "review"
                          : "reviews"}
                        )
                      </Link>
                    </div>
                  </div>
                  <Link to="/all-reviews">
                    <p>{t("All_review")}</p>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="sellers-profile-ctn">
                  <div className="sellers-img-ctn ">
                    <div className="flex-shrink-0 user-area-art">
                      {productInfo?.createdBy?.firstName
                        .charAt(0)
                        .toUpperCase()}
                    </div>
                  </div>
                  <div className="sellers-company my-4">
                    <h2>
                      {productInfo &&
                        Capitalize(productInfo?.createdBy?.firstName)}{" "}
                      {productInfo &&
                        Capitalize(productInfo?.createdBy?.LastName)}
                    </h2>
                    <p>
                      {productInfo?.createdBy?.country &&
                        Capitalize(productInfo?.createdBy?.country)}
                    </p>
                  </div>
                  <p>
                    <Link
                      to={
                        user
                          ? `/sellers-info/${productInfo.createdBy.id}`
                          : "/login"
                      }
                    >
                      {t("View_seller_profile")}
                    </Link>
                  </p>
                  <div className="seller-message-ctn">
                    <Link className="spec-link">
                      <button onClick={addToCart}>{t("Add_to_cart")}</button>
                    </Link>
                    <a href="#inquiry-form">
                      <button className="quote">
                        {t("Request_for_quote")}
                      </button>
                    </a>
                  </div>
                  {/* <MessageSeller
                    supplierInfo={productInfo}
                    Capitalize={Capitalize}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="product-description-ctn">
            <div className="product-description-wrapper d-flex justify-content-start">
              <div
                className={
                  isActive
                    ? "production-specification-title active"
                    : "production-specification-title"
                }
                onClick={() => {
                  setIsActive(true);
                }}
              >
                <h2>{t("Product_Specifications")}</h2>
              </div>
              <div
                className={
                  isActive
                    ? "production-description-title m-0"
                    : "production-description-title active m-0"
                }
                onClick={() => {
                  setIsActive(false);
                }}
              >
                <h2>{t("Product_Description")}</h2>
              </div>
            </div>
            <ProductSpecification
              isActive={isActive}
              productInfo={productInfo}
            />
            <ProductDescription isActive={isActive} productInfo={productInfo} />
          </div>
          {/* <InquireQuote productInfo={productInfo} /> */}

          <section id="inquiry-form">
            <div className="row">
              <div className="col-lg-12">
                <div className="m-product-spec-box">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="modal-contact-info">
                        <div className="contact-paragraph">
                          <p>{t("Submit_your_request")}</p>
                        </div>
                        <div className="contact-info">
                          <div className="contact-name-ctn d-flex justify-content-between m-4">
                            <h2>
                              {user && Capitalize(user.firstName)}{" "}
                              {user && Capitalize(user.LastName)}
                            </h2>
                            <p>
                              {user && (
                                <Link
                                  to={
                                    user.role === "BUYER"
                                      ? "/buyer/settings"
                                      : "/seller/settings"
                                  }
                                >
                                  {t("Change_Info")}{" "}
                                </Link>
                              )}
                            </p>
                          </div>
                          <div className="contact-details">
                            <div className="contact-address">
                              <i
                                className="far fa-sticky-note nav-icon"
                                style={{ color: "#fff" }}
                              ></i>
                              {user?.businessName}
                            </div>
                            <div className="contact-address">
                              <i
                                className="far fa-envelope nav-icon"
                                style={{ color: "#fff" }}
                              ></i>
                              {user?.email}
                            </div>
                            <div className="contact-address">
                              <i
                                className="fas fa-map-marker-alt nav-icon"
                                style={{ color: "#fff" }}
                              ></i>
                              {user?.country}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="inq-form-wrap">
                        <h2 className="quote-title">
                          {t("Request_for_Quote")}
                        </h2>
                        <form onSubmit={handleInquirySubmit}>
                          <div className="mb-3">
                            <label>{t("Product_Requirements")}</label>
                            <textarea
                              className="form-control"
                              rows="3"
                              placeholder="Enter product requirements"
                              name="productDescription"
                              value={inquiry.productDescription}
                              onChange={handleChange}
                              required
                            ></textarea>
                          </div>

                          <div className="row">
                            <div className="col-lg-6 mb-3">
                              <label>{t("Quantity")}</label>
                              <div className="custom-input form-control">
                                <div className="row">
                                  <div className="col-lg-6 col-sm-7 col">
                                    <input
                                      type="number"
                                      className="form-control custom-style"
                                      id=""
                                      placeholder="Enter quantity"
                                      name="quantityRequired"
                                      value={inquiry.quantityRequired}
                                      onChange={handleChange}
                                      required
                                    />
                                  </div>
                                  <div className="col-lg-6 col-sm-5 col">
                                    <select
                                      aria-label="Default select example"
                                      className="unitQuantity"
                                      name="unit"
                                      value={inquiry.unit}
                                      onChange={handleChange}
                                      required
                                    >
                                      <option defaultValue="selected">
                                        {t("select_unit")}
                                      </option>

                                      <option value="Acre(s)">
                                        {t("Acre")}
                                      </option>
                                      <option value="Ampere(s)">
                                        {t("Ampere")}
                                      </option>
                                      <option value="Bag(s)">
                                        {t("Bags")}
                                      </option>
                                      <option value="Barrel(s)">
                                        {t("Barrel")}
                                      </option>
                                      <option value="Box(es)">
                                        {t("Boxes")}
                                      </option>
                                      <option value="Bundle(s)">
                                        {t("Bundle")}
                                      </option>
                                      <option value="Bushel">
                                        {t("Bushel")}
                                      </option>
                                      <option value="Carat(s)">
                                        {t("Carat")}
                                      </option>
                                      <option value="Carton(s)">
                                        {t("Carton")}
                                      </option>
                                      <option value="Case">{t("Case")}</option>
                                      <option value="Centimeter(s)">
                                        {t("Centimeter")}
                                      </option>
                                      <option value="Chain">
                                        {t("Chain")}
                                      </option>
                                      <option value="Container(s)">
                                        {t("Container")}
                                      </option>
                                      <option value="Cubic Foot(s)">
                                        {t("Cubic_Foot")}
                                      </option>
                                      <option value="Cubic Inch(s)">
                                        {t("Cubic_Inch")}
                                      </option>
                                      <option value="Cubic Meter(s)">
                                        {t("Cubic_Meter")}
                                      </option>
                                      <option value="Cubic Yard(s)">
                                        {t("Cubic_Yard")}
                                      </option>
                                      <option value="Dozen(s)">
                                        {t("Dozen")}
                                      </option>
                                      <option value="Dram(s)">
                                        {t("Dram")}
                                      </option>
                                      <option value="Fluid Ounce(s)">
                                        {t("Fluid_Ounce")}
                                      </option>
                                      <option value="Foot">{t("Foot")}</option>
                                      <option value="Gallon(s)">
                                        {t("Gallon")}
                                      </option>
                                      <option value="Gill(s)">
                                        {t("Gill")}
                                      </option>
                                      <option value="Grain(s)">
                                        {t("Grains")}
                                      </option>
                                      <option value="Gram(s)">
                                        {t("Gram")}
                                      </option>
                                      <option value="Hectare(s)">
                                        {t("Hectare")}
                                      </option>
                                      <option value="Hertz">
                                        {t("Hertz")}
                                      </option>
                                      <option value="Inch(s)">
                                        {t("Inch")}
                                      </option>
                                      <option value="Kiloampere(s)">
                                        {t("Kiloampere")}
                                      </option>
                                      <option value="Kilogram(s)">
                                        {t("Kilogram")}
                                      </option>
                                      <option value="Kilohertz">
                                        {t("Kilohertz")}
                                      </option>
                                      <option value="Kilometer(s)">
                                        {t("Kilometer")}
                                      </option>
                                      <option value="Kiloohm(s)">
                                        {t("Kiloohm")}
                                      </option>
                                      <option value="Kilovolt(s)">
                                        {t("Kilovolt")}
                                      </option>
                                      <option value="Kilowatt(s)">
                                        {t("Kilowatt")}
                                      </option>
                                      <option value="Liter(s)">
                                        {t("Liter")}
                                      </option>
                                      <option value="Long Ton(s)">
                                        {t("Long_Ton")}
                                      </option>
                                      <option value="Megahertz">
                                        {t("Megahertz")}
                                      </option>
                                      <option value="Meter(s)">
                                        {t("Meter")}
                                      </option>
                                      <option value="Metric Ton(s)">
                                        {t("Metric_Ton")}
                                      </option>
                                      <option value="Mile(s)">
                                        {t("Mile")}
                                      </option>
                                      <option value="Milliampere(s)">
                                        {t("Milliampere")}
                                      </option>
                                      <option value="Milligram(s)">
                                        {t("Milligram")}
                                      </option>
                                      <option value="Millihertz">
                                        {t("Millihertz")}
                                      </option>
                                      <option value="Milliliter(s)">
                                        Milliliter
                                      </option>
                                      <option value="Millimeter(s)">
                                        Millimeter{" "}
                                      </option>
                                      <option value="Milliohm(s)">
                                        Milliohm
                                      </option>
                                      <option value="Millivolt(s)">
                                        Millivolt
                                      </option>
                                      <option value="Milliwatt(s)">
                                        Milliwatt
                                      </option>
                                      <option value="Ohm(s)">Ohm</option>
                                      <option value="Ounce(s)">Ounce</option>
                                      <option value="Pack(s)">Pack</option>
                                      <option value="Pair(s)">Pairs</option>
                                      <option value="Pallet(s)">Pallet</option>
                                      <option value="Parcel(s)">Parcel</option>
                                      <option value="Perch(s)">Perch</option>
                                      <option value="Piece(s)">Piece</option>
                                      <option value="Pint(s)">Pint</option>
                                      <option value="Plant(s)">Plant</option>
                                      <option value="Pole(s)">Pole</option>
                                      <option value="Pound(s)">Pound</option>
                                      <option value="Quart(s)">Quart</option>
                                      <option value="Quarter(s)">
                                        Quarter
                                      </option>
                                      <option value="Ream(s)">Reams</option>
                                      <option value="Rod(s)">Rod</option>
                                      <option value="Roll(s)">Rolls</option>
                                      <option value="Set(s)">Sets</option>
                                      <option value="Sheet(s)">Sheet</option>
                                      <option value="Short Ton(s)">
                                        Short Ton
                                      </option>
                                      <option value="Square Feet(s)">
                                        Square Feet
                                      </option>
                                      <option value="Square Inch(s)">
                                        Square Inch
                                      </option>
                                      <option value="Square Meter(s)">
                                        Square Meters
                                      </option>
                                      <option value="Square Mile(s)">
                                        Square Mile
                                      </option>
                                      <option value="Square Yard(s)">
                                        Square Yard
                                      </option>
                                      <option value="Stone(s)">Stone</option>
                                      <option value="Strand(s)">Strand</option>
                                      <option value="Tonne(s)">Tonne</option>
                                      <option value="Tray(s)">Tray</option>
                                      <option value="Unit(s)">Unit</option>
                                      <option value="Volt(s)">Volt</option>
                                      <option value="Watt(s)">Watt</option>
                                      <option value="Wp(s)">Wp</option>
                                      <option value="Yard(s)">Yard</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-3">
                              <label>{t("Shipping_Terms")}</label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="termsOfTrade"
                                value={inquiry.termsOfTrade}
                                onChange={handleChange}
                              >
                                <option defaultValue="selected">
                                  {t("Select_Incoterms")}
                                </option>
                                <option value="FOB">FOB</option>
                                <option value="CIF">CIF</option>
                                <option value="CFR">CFR</option>
                                <option value="LOCAL_DELIVERY">
                                  {t("Local_Delivery")}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-lg-6 mb-3">
                              <label>{t("Payment_Terms")}</label>
                              <select
                                className="form-select"
                                aria-label="Default select example"
                                name="paymentTerms"
                                value={inquiry.paymentTerms}
                                onChange={handleChange}
                              >
                                <option defaultValue="selected">
                                  {t("Select_payment_terms")}
                                </option>
                                <option value="PAYNOW">{t("Pay_Now")}</option>
                                <option value="TRANSFER">
                                  {t("Transfer")}
                                </option>
                                <option value="LC">
                                  {t("Letter_of_Credit")}
                                </option>
                                <option value="TT">
                                  {t("Telegraphic_Transfer")}
                                </option>
                              </select>
                            </div>
                            <div className="col-lg-6 mb-3 price-form">
                              <label>{t("Destination_port/_Location")}</label>

                              <input
                                className="form-select"
                                name="destinationPort"
                                value={inquiry.destinationPort}
                                onChange={handleChange}
                                placeholder="Lagos port, Nigeria"
                              />
                            </div>
                            <div className="col-lg-6 mb-3 price-form">
                              <label>
                                {t("Price")} {selectedCurrency}
                              </label>
                              <input
                                className="form-select"
                                name="targetPrice"
                                value={inquiry.targetPrice}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          {!loader ? (
                            <button className="mt-3" type="submit">
                              {t("Submit_Quote")}
                            </button>
                          ) : (
                            <button className="mt-3" type="submit">
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </button>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div>
            <NewlyAddedProducts
              sectionTitle={"You might be interested in"}
              convertPrice={convertPrice}
              selectedCurrency={selectedCurrency}
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ProductInfo;
