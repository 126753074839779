import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ChangePasswordModal from "../SellersComponent/ChangePasswordModal";
import { convertDateFormat } from "../../../../components/helpers/convertDate";
import { Link } from "react-router-dom";

const SellersProfile = ({ user }) => {
  const { t } = useTranslation();
  const [joined] = useState(user.createdAt && user.createdAt);

  return (
    <>
      <form>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("First_name")}</label>
              <div className="div-form-control">
                {user?.firstName && user?.firstName}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("Last_name")}</label>
              <div className="div-form-control">
                {user?.LastName && user?.LastName}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("Email")}</label>
              <div className="div-form-control">
                {user?.email && user?.email}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("Phone_number")}</label>
              <div className="div-form-control">
                {user?.phoneNumber && user?.phoneNumber}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("Role")}</label>
              <div className="div-form-control">{user?.role && user?.role}</div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("Business_Name")}</label>
              <div className="div-form-control">
                {user?.businessName && user?.businessName}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("Business_type")}</label>
              <div className="div-form-control">
                {user?.businessType && user?.businessType}
              </div>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="form-group">
              <label>{t("Business_Description")}</label>
              <div className="div-form-control">
                {user?.businessDescription && user?.businessDescription}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("Total_Annual_Revenues")}</label>

              <div className="div-form-control">
                {user?.totalAnnualRevenue && user?.totalAnnualRevenue}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("Supply_Capacity")}</label>

              <div className="div-form-control">
                {user?.supplyCapacity && user?.supplyCapacity}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("Country")}</label>
              <div className="div-form-control">
                {user?.country && user?.country}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>{t("Year_Established")}</label>
              <div className="div-form-control">
                {user?.yearEstablished && user?.yearEstablished}
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>{t("Address")}</label>
            <div className="div-form-control">
              {user?.address && user?.address}
            </div>
          </div>
        </div>
        <div className="seller-footer">
          <div className="seller-seting-submit">
            <Link
              to="/seller/settings/edit"
              className=" savechanges  me-3 px-3 py-2"
            >
              {t("Edit_profile")}
            </Link>

            <button
              type="button"
              className="btn btn-primary changepassword"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal1"
            >
              {t("Change_Password")}
            </button>
          </div>
          <div className="seller-seting-joindate">
            <p>{t("Joined_Since")}</p>
            <p>{joined && convertDateFormat(joined)}</p>
          </div>
        </div>
      </form>
      {/* <EditProfileModal /> */}
      <ChangePasswordModal />
    </>
  );
};

export default SellersProfile;
