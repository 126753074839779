import React from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

const UserDetails = ({
  user,
  address,
  email,
  setEmail,
  lastName,
  setLastName,
  firstName,
  setFirstName,
  handleAddressChange,
  handleEditProfile,
  updateLoader,
  handlePlacesChanged,
  autocompleteRef,
  isChange,
  setIsChange,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {" "}
      <div className="col-12">
        <div className="over-card user-profile-ctn">
          <h5>{t("User_details")}</h5>

          <div className="user-details-ctn">
            <div className="user-ctn">
              <div className="user-name">
                <p>{t("Phone number")}</p>
                <p>{user?.phoneNumber && user?.phoneNumber}</p>
              </div>
              <div className="user-name">
                <p>{t("First name")}</p>
                <p>
                  {!isChange ? (
                    <>{firstName ? firstName : "Enter first name"}</>
                  ) : (
                    <input
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  )}{" "}
                </p>
              </div>
              <div className="user-name">
                <p>{t("Last name")}</p>
                <p>
                  {!isChange ? (
                    <>{lastName ? lastName : "Enter last name"}</>
                  ) : (
                    <input
                      type="text"
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  )}{" "}
                </p>
              </div>

              <div className="user-name">
                <p>{t("Email")}</p>
                <p>
                  {!isChange ? (
                    <>{email ? email : "Enter email address"}</>
                  ) : (
                    <input
                      type="text"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  )}{" "}
                </p>
              </div>
              <div className="user-name">
                <p>{t("Address")}</p>
                {!isChange ? (
                  <p>{address ? address : "No address"}</p>
                ) : (
                  <StandaloneSearchBox
                    onLoad={(ref) => (autocompleteRef.current = ref)}
                    onPlacesChanged={handlePlacesChanged}
                  >
                    <input
                      type="text"
                      name="address"
                      value={address}
                      onChange={handleAddressChange}
                    />
                  </StandaloneSearchBox>
                )}
              </div>
              {/* <div className="user-name">
                <p>{t("Country")}</p>
                {isChange ? (
                  <input
                    type="text"
                    name="country"
                    value={country}
                    onChange={handleCountryChange}
                  />
                ) : (
                  <p>{country ? country : "No country"}</p>
                )}
              </div> */}
            </div>

            <div className="user-ctn">
              {isChange ? (
                <>
                  {" "}
                  {updateLoader ? (
                    <button>
                      {" "}
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  ) : (
                    <button type="button" onClick={handleEditProfile}>
                      {t("Save")}
                    </button>
                  )}
                </>
              ) : (
                <button type="button" onClick={() => setIsChange(true)}>
                  {t("Change")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
