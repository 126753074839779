import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import "./AllRfq.css";
import { Link, useNavigate } from "react-router-dom";
import CreateMultipleRfq from "./CreateMultipleRfq";
import MultipleRfqModal from "./MultipleRfqModal";
import { GlobalContext } from "../../components/utils/GlobalState";
import RfqSkeleton from "../../components/SkeletonLoader/RfqSkeleton";
import { useGetAllRfqs } from "../../components/hooks/useGetAllRfqs";
import { useGetRfqById } from "../../components/hooks/useGetRfqById";
import { convertDateFormat } from "../../components/helpers/convertDate";
import {
  numberWithCommas,
  Capitalize,
} from "../../components/helpers/functionHelpers";
import cookies from "js-cookie";
import { useEventTag } from "../../components/hooks/useEventTag";

const Rfqs = () => {
  const { t } = useTranslation();
  const { user } = useContext(GlobalContext);
  const { allRfq, rfqLoading } = useGetAllRfqs();
  const { rfqInfoLoad, rfqInfo, viewRfqInfo } = useGetRfqById();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const selectedCurrency = cookies.get("currency") || "NGN";
  const { handleEventTag } = useEventTag();

  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleEventAction = (productName) => {
    if (user) {
      handleEventTag(`r_${productName}`, user.id);
    } else {
      handleEventTag(`r_${productName}`);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      <Header />
      <div>
        <div className="rfq-landing-container">
          <div className="container">
            <div className="rfq-cards">
              <section id="rfq-list">
                <div className="container">
                  <div className="section-head">
                    <h4 className="back-btn">
                      <i className="fas fa-arrow-left"></i> RFQ's
                    </h4>
                  </div>
                  {rfqLoading ? (
                    <RfqSkeleton />
                  ) : (
                    <div className="rfq-list-wrap">
                      {allRfq?.slice(0, 6).map((rfq) => (
                        <div className="rfq-list-box" key={rfq.id}>
                          <h2>
                            {rfq.productName && Capitalize(rfq.productName)}
                          </h2>
                          <h6>
                            {rfq.user && Capitalize(rfq.user.firstName)}{" "}
                            {rfq.user && Capitalize(rfq.user.LastName)}
                          </h6>
                          <h3>
                            {t("Quantity")}:{" "}
                            {rfq && numberWithCommas(rfq.quantityRequired)}{" "}
                            {rfq && rfq.unit}
                          </h3>
                          <p>
                            {t("Date_created")}:{" "}
                            {rfq && convertDateFormat(rfq.createdAt)}
                            <span>
                              <a
                                data-bs-toggle="modal"
                                href="#exampleModalToggle"
                                role="button"
                                onClick={(e) => {
                                  handleEventAction(rfq.productName);
                                  viewRfqInfo(rfq.id);
                                }}
                              >
                                {t("View_details")}
                              </a>
                            </span>
                          </p>
                          <MultipleRfqModal
                            rfqInfo={rfqInfo}
                            numberWithCommas={numberWithCommas}
                            Capitalize={Capitalize}
                            convertDateFormat={convertDateFormat}
                            rfqInfoLoad={rfqInfoLoad}
                            selectedCurrency={selectedCurrency}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </section>
              <div className="response-quote">
                <div className="quote-text">
                  <h5>{t("Get_Multiple")}</h5>
                  <p>{t("Are_you_interested")}</p>
                </div>
                {user ? (
                  <div className="request-quote">
                    <button
                      className="request-quotebtn"
                      // data-bs-toggle="modal"
                      // data-bs-target="#exampleModal"
                      onClick={showModal}
                    >
                      {t("Request_Quote")}
                    </button>
                  </div>
                ) : (
                  <Link to="/login" className="request-quote">
                    <button className="request-quotebtn">
                      {t("Request_Quote")}
                    </button>
                  </Link>
                )}
              </div>
              <div className="rfq-cards">
                <section id="rfq-list">
                  <div className="container">
                    {rfqLoading ? (
                      <RfqSkeleton />
                    ) : (
                      <div className="rfq-list-wrap">
                        {allRfq?.slice(6, allRfq.length).map((rfq) => (
                          <div className="rfq-list-box" key={rfq.id}>
                            <h2>
                              {rfq.productName && Capitalize(rfq.productName)}
                            </h2>
                            <h6>
                              {rfq.user && Capitalize(rfq.user.firstName)}{" "}
                              {rfq.user && Capitalize(rfq.user.LastName)}
                            </h6>
                            <h3>
                              Quantity:{" "}
                              {rfq && numberWithCommas(rfq.quantityRequired)}{" "}
                              {rfq && rfq.unit}
                            </h3>
                            <p>
                              Date created:{" "}
                              {rfq && convertDateFormat(rfq.createdAt)}
                              <span>
                                <a
                                  data-bs-toggle="modal"
                                  href="#exampleModalToggle"
                                  role="button"
                                  onClick={(e) => {
                                    viewRfqInfo(rfq.id);
                                    handleEventAction(rfq.productName);
                                  }}
                                >
                                  {t("View_details")}
                                </a>
                              </span>
                            </p>
                            <MultipleRfqModal
                              rfqInfo={rfqInfo}
                              numberWithCommas={numberWithCommas}
                              Capitalize={Capitalize}
                              convertDateFormat={convertDateFormat}
                              rfqInfoLoad={rfqInfoLoad}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </section>
              </div>
              <div className="response-quote">
                <div className="quote-text">
                  <h5>{t("Get_Multiple")}</h5>
                  <p>{t("Are_you_interested")}</p>
                </div>
                {user ? (
                  <div className="request-quote">
                    <button
                      className="request-quotebtn"
                      // data-bs-toggle="modal"
                      // data-bs-target="#exampleModal"
                      isModalOpen={isModalOpen}
                      handleOk={handleOk}
                    >
                      {t("Request_Quote")}
                    </button>
                  </div>
                ) : (
                  <Link to="/login" className="request-quote">
                    <button className="request-quotebtn">
                      {t("Request_Quote")}
                    </button>
                  </Link>
                )}
              </div>
              <CreateMultipleRfq
                isModalOpen={isModalOpen}
                handleOk={handleOk}
                setIsModalOpen={setIsModalOpen}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Rfqs;
