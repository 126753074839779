import { useNavigate } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";

import "./Product.css";

import cookies from "js-cookie";
import Form from "react-bootstrap/Form";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";

import SellersHeader from "../SellersComponent/SellersHeader";
import SellersSidebar from "../SellersComponent/SellerSideBar";

import { africaCountryData } from "./africaCountries";
import { axiosInstance } from "../../../../components/baseUrl.jsx";
import { BigCard } from "../../../../components/SkeletonLoader/BigCard";
import { GlobalContext } from "../../../../components/utils/GlobalState";
import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { toastOptions } from "../../../../components/helpers/functionHelpers.js";
import { useGetAllCategories } from "../../../../components/hooks/useGetAllCategories";

const SellersNewProducts = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { user, userLoading } = useContext(GlobalContext);
  const { allCategories, cateLoading } = useGetAllCategories();
  const [show, setShow] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [specifications, setSpecifications] = useState([
    { type: "", value: "" },
  ]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedImageFiles, setSelectedImageFiles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActiv, setIsActiv] = useState(false);
  const [selectedCurrenci, setSelectedCurrenci] = useState("");

  const selectedCurrency = cookies.get("currency") || "NGN";
  const currentSelectedCurrency = selectedCurrenci || selectedCurrency;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  // const handleShow = (index) => {
  //   setShow(index);
  //   setIsActiv((current) => !current);
  // };

  const [createNewProduct, setCreateNewProduct] = useState({
    productName: "",
    country: "",
    price: "",
    supplyCapacity: "",
    minLeadTime: "",
    maxLeadTime: "",
    minOrder: "",
    productDescription: "",
    unitForMinOrder: "",
    unitForSupplyCapacity: "",
    minOrdersAllowed: "",
  });

  const toggleFormCheck = (id) => {
    if (selectedCategory?.includes(id)) {
      setSelectedCategory(
        selectedCategory?.filter((checkId) => checkId !== id)
      );
    } else {
      setSelectedCategory([...selectedCategory, id]);
    }
  };

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrenci(e.target.value);
    cookies.set("currency", e.target.value);
  };

  const handleClickk = (id) => {
    setShow(id);
    setIsActiv((current) => !current);
  };

  const handleInput = (index, event) => {
    const values = [...specifications];
    values[index][event.target.name] = event.target.value;
    setSpecifications(values);
  };

  const handleAddFields = () => {
    setSpecifications([...specifications, { type: "", value: "" }]);
  };

  const handleRemoveFields = (index) => {
    const values = [...specifications];
    if (values.length > 1) {
      values.splice(index, 1);
      setSpecifications(values);
    }
  };

  const handleChange = (e) => {
    setCreateNewProduct({
      ...createNewProduct,
      [e.target.name]: e.target.value,
    });
  };

  const onSelectFile = (e) => {
    const selectedFiles = e.target.files;
    const newImages = [...selectedImageFiles];
    for (let i = 0; i < e.target.files.length; i++) {
      newImages.push(e.target.files[i]);
    }
    const selectedFilesArray = Array.from(selectedFiles);
    // const invalidFiles = [];

    //handling error when image is more than 350kb
    // selectedFilesArray.forEach((file) => {
    //   const fileSize = Math.round(file.size / 1024); // Size in kilobytes
    //   if (fileSize > 350) {
    //     invalidFiles.push(file.name);
    //   }
    // });
    // if (invalidFiles.length === 1) {
    //   const errorMessage = `Image is too large. Image must not exceed 350KB! `;
    //   toast.error(`${errorMessage} `, {
    //     duration: 4000,
    //     style: {
    //       background: "#353434",
    //       color: "#fff",
    //     },
    //   });
    //   e.target.value = null;
    //   setSelectedImageFiles([]);
    //   setSelectedImages(null);
    // } else if (invalidFiles.length > 1) {
    //   const errorMessage = `The following images exceed the maximum size of 350KB:\n${invalidFiles.join(
    //     ",\n"
    //   )}`;
    //   toast.error(`${errorMessage} `, {
    //     duration: 4000,
    //     style: {
    //       background: "#353434",
    //       color: "#fff",
    //     },
    //   });
    //   e.target.value = null;
    //   setSelectedImageFiles([]);
    //   setSelectedImages(null);
    // } else {
    const imagesArray = selectedFilesArray?.map((file) => {
      return URL.createObjectURL(file);
    });
    setSelectedImages((previousImages) => previousImages?.concat(imagesArray));
    setSelectedImageFiles(newImages);
    // }
  };

  const handleImageDelete = (index) => {
    const newImages = [...selectedImageFiles];
    newImages.splice(index, 1);
    setSelectedImageFiles(newImages);
  };

  const getSpecifications = () => {
    const keys = document.getElementsByClassName("specification-keys");
    const values = document.getElementsByClassName("specification-values");

    const specification = {};
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i].value;
      const value = values[i].value;
      if (key && value) specification[key] = value;
    }
    return JSON.stringify(specification);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (createNewProduct.productName.length === 0) {
      toast.error("Please enter product name", toastOptions);
    } else if (
      createNewProduct.price === "0" ||
      createNewProduct.price.length === 0
    ) {
      toast.error("Please enter price", toastOptions);
    } else if (createNewProduct.country.length === 0) {
      toast.error("Please select country", toastOptions);
    } else if (createNewProduct.supplyCapacity.length === 0) {
      toast.error("Please enter supply capacity", toastOptions);
    } else if (createNewProduct.unitForSupplyCapacity.length === 0) {
      toast.error("Please enter unit for supply capacity", toastOptions);
    } else if (createNewProduct.minLeadTime.length === 0) {
      toast.error("Please enter min duration", toastOptions);
    } else if (createNewProduct.maxLeadTime.length === 0) {
      toast.error("Please enter max duration", toastOptions);
    } else if (
      createNewProduct.minOrdersAllowed.length === 0 ||
      createNewProduct.minOrdersAllowed === "0"
    ) {
      toast.error("Please enter your min order allowed", toastOptions);
    } else if (specifications[0].type.length === 0) {
      toast.error("Please enter specification type", toastOptions);
    } else if (specifications[0].value.length === 0) {
      toast.error("Please enter specification value", toastOptions);
    } else if (createNewProduct.unitForMinOrder.length === 0) {
      toast.error("Please enter unit for min order", toastOptions);
    } else if (createNewProduct.productDescription.length === 0) {
      toast.error("Please enter product description", toastOptions);
    } else if (selectedCategory.length < 1) {
      toast.error("Please select a category", toastOptions);
    } else if (selectedImages.length === 0 || selectedImageFiles.length === 0) {
      toast.error("Please select an image", toastOptions);
    } else {
      setLoader(true);
      try {
        const createProduct = {
          userId: user.id,
          productName: createNewProduct.productName,
          supplyCapacity: createNewProduct.supplyCapacity,
          unitForSupplyCapacity: createNewProduct.unitForSupplyCapacity,
          unitForMinOrder: createNewProduct.unitForMinOrder,
          minDuration: createNewProduct.minLeadTime,
          maxDuration: createNewProduct.maxLeadTime,
          productDescription: createNewProduct.productDescription,
          specification: getSpecifications(),
          countryOfOrigin: createNewProduct.country,
          minOrdersAllowed: parseFloat(createNewProduct.minOrdersAllowed),
          categoryIds: selectedCategory,
          minPricePerUnit: createNewProduct.price,
          currencyType: currentSelectedCurrency,
          maxPricePerUnit: 0,
        };
        const formData = new FormData();
        for (const property in createProduct) {
          formData.append(`${property}`, createProduct[property]);
        }

        for (let i = 0; i < selectedImageFiles.length; i++) {
          formData.append("otherImages", selectedImageFiles[i]);
        }

        await axiosInstance.post("/product", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setLoader(false);

        toast.success(
          "Product uploaded successfully, Wait for product approval.",
          {
            duration: 4000,
            style: {
              background: "#353434",
              color: "#fff",
            },
          }
        );
        setCreateNewProduct({
          productName: "",
          country: "",
          price: "",
          supplyCapacity: "",
          minLeadTime: "",
          maxLeadTime: "",
          minOrder: "",
          productDescription: "",
          unitForMinOrder: "",
          unitForSupplyCapacity: "",
          minOrdersAllowed: "",
        });
        setSelectedCategory(null);
        setSelectedImageFiles(null);
        setSpecifications([{ type: "", value: "" }]);

        setTimeout(() => {
          navigate(-1);
        }, 4600);
      } catch (error) {
        setLoader(false);
        console.log(error);
        if (!error.response.data.errors) {
          return navigate(`/no-connection`);
        }
        toast.error(`${error.response.data.errors[0].message}`, {
          duration: 4000,
          style: {
            background: "#353434",
            color: "#fff",
          },
        });
      }
    }
  };

  return (
    <>
      <Toaster />
      <div>
        <div className="grid-container">
          <SellersHeader
            user={user}
            userLoading={userLoading}
            handleClick={handleClick}
            title="Create New Product"
          />

          <SellersSidebar isActive={isActive} handleClick={handleClick} />

          {cateLoading ? (
            <BigCard />
          ) : (
            <main className="main">
              <div className="main-overview new-product-container">
                <div>
                  <div className="list-new-product-title-ctn">
                    <h3>{t("Product_information")}</h3>

                    <p className="imp-info-text">
                      {t("To_List_a_new_product")}
                    </p>
                  </div>

                  <div className="overview-card new-product padding-10">
                    <div className="row">
                      <div className="col-md-6 col-sm-10 product-row-info">
                        <label>{t("Product_Name")}</label>
                        <p className="imp-info-text">{t("Enter_name")}</p>
                        <input
                          className="form-control"
                          type="text"
                          id="fullname"
                          name="productName"
                          value={createNewProduct.productName}
                          onChange={handleChange}
                          placeholder="Vintage shirt"
                          required
                        />
                      </div>
                      <div className="col-lg-6 col-md-8 col-sm-10 product-row-info">
                        <label>{t("Minimum_Order")}</label>
                        <p className="imp-info-text">
                          {t("Enter_the_minimum")}
                        </p>

                        <div className="supplycapacitydiv">
                          <input
                            type="number"
                            name="minOrdersAllowed"
                            placeholder="1"
                            value={createNewProduct.minOrdersAllowed}
                            onChange={handleChange}
                          />
                          <select
                            aria-label="Default select example"
                            name="unitForMinOrder"
                            value={createNewProduct.unitForMinOrder}
                            onChange={handleChange}
                            required
                          >
                            <option value="Unit">{t("select_unit")}</option>
                            <option value="Acres">Acre</option>
                            <option value="Amperes">Ampere</option>
                            <option value="Bags">Bags</option>
                            <option value="Barrels">Barrel</option>
                            <option value="Box(es)">Boxes</option>
                            <option value="Bundles">Bundle</option>
                            <option value="Bushel">Bushel</option>
                            <option value="Carats">Carat</option>
                            <option value="Cartons">Carton</option>
                            <option value="Case">Case</option>
                            <option value="Centimeters">Centimeter</option>
                            <option value="Chain">Chain</option>
                            <option value="Containers">Container</option>
                            <option value="Cubic Foots">Cubic Foot</option>
                            <option value="Cubic Inchs">Cubic Inch</option>
                            <option value="Cubic Meters">Cubic Meter</option>
                            <option value="Cubic Yards">Cubic Yard</option>
                            <option value="Dozens">Dozen</option>
                            <option value="Drams">Dram</option>
                            <option value="Fluid Ounces">Fluid Ounce</option>
                            <option value="Foot">Foot</option>
                            <option value="Gallons">Gallon</option>
                            <option value="Gills">Gill</option>
                            <option value="Grains">Grains</option>
                            <option value="Grams">Gram</option>
                            <option value="Hectares">Hectare</option>
                            <option value="Hertz">Hertz</option>
                            <option value="Inchs">Inch</option>
                            <option value="Kiloamperes">Kiloampere</option>
                            <option value="Kilograms">Kilogram</option>
                            <option value="Kilohertz">Kilohertz</option>
                            <option value="Kilometers">Kilometer</option>
                            <option value="Kiloohms">Kiloohm</option>
                            <option value="Kilovolts">Kilovolt</option>
                            <option value="Kilowatts">Kilowatt</option>
                            <option value="Liters">Liter</option>
                            <option value="Long Tons">Long Ton</option>
                            <option value="Megahertz">Megahertz</option>
                            <option value="Meters">Meter</option>
                            <option value="Metric Tons">Metric Ton</option>
                            <option value="Miles">Mile</option>
                            <option value="Milliamperes">Milliampere</option>
                            <option value="Milligrams">Milligram</option>
                            <option value="Millihertz">Millihertz</option>
                            <option value="Milliliters">Milliliter</option>
                            <option value="Millimeters">Millimeter </option>
                            <option value="Milliohms">Milliohm</option>
                            <option value="Millivolts">Millivolt</option>
                            <option value="Milliwatts">Milliwatt</option>
                            <option value="Ohms">Ohm</option>
                            <option value="Ounces">Ounce</option>
                            <option value="Packs">Pack</option>
                            <option value="Pairs">Pairs</option>
                            <option value="Pallets">Pallet</option>
                            <option value="Parcels">Parcel</option>
                            <option value="Perchs">Perch</option>
                            <option value="Pieces">Piece</option>
                            <option value="Pints">Pint</option>
                            <option value="Plants">Plant</option>
                            <option value="Poles">Pole</option>
                            <option value="Pounds">Pound</option>
                            <option value="Quarts">Quart</option>
                            <option value="Quarters">Quarter</option>
                            <option value="Reams">Reams</option>
                            <option value="Rods">Rod</option>
                            <option value="Rolls">Rolls</option>
                            <option value="Sets">Sets</option>
                            <option value="Sheets">Sheet</option>
                            <option value="Short Tons">Short Ton</option>
                            <option value="Square Feets">Square Feet</option>
                            <option value="Square Inchs">Square Inch</option>
                            <option value="Square Meters">Square Meters</option>
                            <option value="Square Mile(s)">Square Mile</option>
                            <option value="Square Yard(s)">Square Yard</option>
                            <option value="Stone(s)">Stone</option>
                            <option value="Strand(s)">Strand</option>
                            <option value="Tonne(s)">Tonne</option>
                            <option value="Tray(s)">Tray</option>
                            <option value="Unit(s)">Unit</option>
                            <option value="Volt(s)">Volt</option>
                            <option value="Watt(s)">Watt</option>
                            <option value="Wp(s)">Wp</option>
                            <option value="Yard(s)">Yard</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-lg-6 col-md-8 col-sm-10 product-row-info">
                        <label>{t("Country_of_Origin")}</label>
                        <Form.Select
                          size="sm"
                          name="country"
                          value={createNewProduct.country}
                          onChange={handleChange}
                        >
                          <option defaultValue="selected">
                            {t("Select_country")}
                          </option>
                          {Object.entries(africaCountryData).map(
                            (country, index) => (
                              <option key={index} value={`${country[1]}`}>
                                {country[1]}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </div>

                      <div className="col-lg-6 col-md-8 col-sm-10 product-row-info">
                        <label>{t("Supply_Capacity")}</label>
                        <div className="supplycapacitydiv">
                          <input
                            type="number"
                            name="supplyCapacity"
                            value={createNewProduct.supplyCapacity}
                            onChange={handleChange}
                            required
                            placeholder="55"
                          />
                          <select
                            aria-label="Default select example"
                            name="unitForSupplyCapacity"
                            value={createNewProduct.unitForSupplyCapacity}
                            onChange={handleChange}
                            required
                          >
                            <option defaultValue="selected">
                              {t("select_unit")}
                            </option>
                            <option value="Acres">Acre</option>
                            <option value="Amperes">Ampere</option>
                            <option value="Bags">Bags</option>
                            <option value="Barrels">Barrel</option>
                            <option value="Box(es)">Boxes</option>
                            <option value="Bundles">Bundle</option>
                            <option value="Bushel">Bushel</option>
                            <option value="Carats">Carat</option>
                            <option value="Cartons">Carton</option>
                            <option value="Case">Case</option>
                            <option value="Centimeters">Centimeter</option>
                            <option value="Chain">Chain</option>
                            <option value="Containers">Container</option>
                            <option value="Cubic Foots">Cubic Foot</option>
                            <option value="Cubic Inchs">Cubic Inch</option>
                            <option value="Cubic Meters">Cubic Meter</option>
                            <option value="Cubic Yards">Cubic Yard</option>
                            <option value="Dozens">Dozen</option>
                            <option value="Drams">Dram</option>
                            <option value="Fluid Ounces">Fluid Ounce</option>
                            <option value="Foot">Foot</option>
                            <option value="Gallons">Gallon</option>
                            <option value="Gills">Gill</option>
                            <option value="Grains">Grains</option>
                            <option value="Grams">Gram</option>
                            <option value="Hectares">Hectare</option>
                            <option value="Hertz">Hertz</option>
                            <option value="Inchs">Inch</option>
                            <option value="Kiloamperes">Kiloampere</option>
                            <option value="Kilograms">Kilogram</option>
                            <option value="Kilohertz">Kilohertz</option>
                            <option value="Kilometers">Kilometer</option>
                            <option value="Kiloohms">Kiloohm</option>
                            <option value="Kilovolts">Kilovolt</option>
                            <option value="Kilowatts">Kilowatt</option>
                            <option value="Liters">Liter</option>
                            <option value="Long Tons">Long Ton</option>
                            <option value="Megahertz">Megahertz</option>
                            <option value="Meters">Meter</option>
                            <option value="Metric Tons">Metric Ton</option>
                            <option value="Miles">Mile</option>
                            <option value="Milliamperes">Milliampere</option>
                            <option value="Milligrams">Milligram</option>
                            <option value="Millihertz">Millihertz</option>
                            <option value="Milliliters">Milliliter</option>
                            <option value="Millimeters">Millimeter </option>
                            <option value="Milliohms">Milliohm</option>
                            <option value="Millivolts">Millivolt</option>
                            <option value="Milliwatts">Milliwatt</option>
                            <option value="Ohms">Ohm</option>
                            <option value="Ounces">Ounce</option>
                            <option value="Packs">Pack</option>
                            <option value="Pairs">Pairs</option>
                            <option value="Pallets">Pallet</option>
                            <option value="Parcels">Parcel</option>
                            <option value="Perchs">Perch</option>
                            <option value="Pieces">Piece</option>
                            <option value="Pints">Pint</option>
                            <option value="Plants">Plant</option>
                            <option value="Poles">Pole</option>
                            <option value="Pounds">Pound</option>
                            <option value="Quarts">Quart</option>
                            <option value="Quarters">Quarter</option>
                            <option value="Reams">Reams</option>
                            <option value="Rods">Rod</option>
                            <option value="Rolls">Rolls</option>
                            <option value="Sets">Sets</option>
                            <option value="Sheets">Sheet</option>
                            <option value="Short Tons">Short Ton</option>
                            <option value="Square Feets">Square Feet</option>
                            <option value="Square Inchs">Square Inch</option>
                            <option value="Square Meters">Square Meters</option>
                            <option value="Square Miles">Square Mile</option>
                            <option value="Square Yards">Square Yard</option>
                            <option value="Stones">Stone</option>
                            <option value="Strands">Strand</option>
                            <option value="Tonnes">Tonne</option>
                            <option value="Trays">Tray</option>
                            <option value="Units">Unit</option>
                            <option value="Volts">Volt</option>
                            <option value="Watts">Watt</option>
                            <option value="Wps">Wp</option>
                            <option value="Yards">Yard</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-10 product-row-info">
                        <label>{t("Min_delivery_duration")}</label>
                        <input
                          className="form-control"
                          type="text"
                          id="lead time max"
                          name="minLeadTime"
                          value={createNewProduct.minLeadTime}
                          onChange={handleChange}
                          placeholder="1 day"
                        />
                      </div>
                      <div className="col-md-6 col-sm-10 product-row-info">
                        <label>{t("Max_delivery_duration")}</label>
                        <input
                          className="form-control"
                          type="text"
                          id="lead time min"
                          name="maxLeadTime"
                          value={createNewProduct.maxLeadTime}
                          onChange={handleChange}
                          placeholder="15 days"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6 product-row-info ">
                        <label>{t("Product_Specification")}</label>
                        <p className="imp-info-text">
                          {t("Click_on_the_plus")}
                        </p>
                        {specifications.map((specification, index) => (
                          <div className="row mb-4" key={index}>
                            <div className=" col-sm-6 col-10 mb-2">
                              <input
                                className="form-control specification-keys"
                                value={specification.type}
                                onChange={(event) => handleInput(index, event)}
                                type="text"
                                id="type"
                                name="type"
                                placeholder="color"
                              />
                            </div>

                            <div className="col-sm-6 col-10 mb-2">
                              <div className="productspecification-value ">
                                <input
                                  className="form-control specification-values"
                                  type="text"
                                  id="type"
                                  value={specification.value}
                                  onChange={(event) =>
                                    handleInput(index, event)
                                  }
                                  name="value"
                                  placeholder="blue, red"
                                />
                                <div>
                                  <button>
                                    <i
                                      className="fas fa-plus"
                                      onClick={() => handleAddFields()}
                                    ></i>
                                  </button>
                                  <button>
                                    <i
                                      className="fas fa-minus"
                                      onClick={() => handleRemoveFields()}
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="col-md-6 col-sm-10 product-row-info">
                        <label>
                          {t("Price")} ({currentSelectedCurrency}/
                          {createNewProduct.unitForMinOrder
                            ? createNewProduct.unitForMinOrder
                            : "Unit"}
                          )
                        </label>
                        <p className="imp-info-text">{t("Enter_price")}</p>
                        <div className="supplycapacitydiv">
                          <input
                            className="form-control"
                            type="number"
                            name="price"
                            value={createNewProduct.price}
                            onChange={handleChange}
                            required
                            placeholder="10"
                          />
                          <select
                            name="selectedCurrenci"
                            value={selectedCurrenci}
                            onChange={handleCurrencyChange}
                          >
                            <option value={currentSelectedCurrency}>
                              {currentSelectedCurrency}
                            </option>
                            {currentSelectedCurrency !== "USD" && (
                              <option value="USD">USD</option>
                            )}
                            {currentSelectedCurrency !== "NGN" && (
                              <option value="NGN">NGN</option>
                            )}
                            {currentSelectedCurrency !== "EUR" && (
                              <option value="EUR">EUR</option>
                            )}
                            {currentSelectedCurrency !== "GBP" && (
                              <option value="GBP">GBP</option>
                            )}
                            {currentSelectedCurrency !== "GHS" && (
                              <option value="GHS">GHS</option>
                            )}
                            {currentSelectedCurrency !== "XOF" && (
                              <option value="XOF">XOF</option>
                            )}
                          </select>
                        </div>
                      </div>
                    </div>

                    <label>{t("Product_Description")}</label>
                    <div className="product-info-description pro-des">
                      <textarea
                        className="form-control"
                        type="text"
                        id="text"
                        cols="20"
                        name="productDescription"
                        value={createNewProduct.productDescription}
                        onChange={handleChange}
                        placeholder="I have original vintage..."
                      />
                    </div>
                  </div>
                </div>

                <div className="categories">
                  <h6>{t("Categories")}</h6>
                  <p className="imp-info-text">
                    {t("Selecting_the_right_category")}
                  </p>
                  <div className="overview-card categorieslist-container padding-10">
                    {allCategories?.map((category, index) => (
                      <div key={index}>
                        <div
                          className="categorieslist"
                          onClick={() => handleClickk(category.id)}
                        >
                          <label
                            htmlFor={category.category}
                            className=" categoriesdescription"
                          >
                            <img src={category.icon} alt="" />
                            <p>{category.category}</p>
                          </label>

                          <Form.Check
                            checked={selectedCategory?.includes(category.id)}
                            aria-label={index}
                            value={category.id}
                            onChange={() => toggleFormCheck(category.id)}
                            id={category.category}
                          />
                        </div>
                        <div
                          className={
                            category.id === show && isActiv ? " showw" : "hidee"
                          }
                        >
                          {category?.children &&
                            category?.children?.length > 0 &&
                            category?.children?.map(
                              (layerOneCategory, index) => (
                                <div key={layerOneCategory.id}>
                                  <div className="categorieslist">
                                    <label
                                      htmlFor={layerOneCategory.category}
                                      className="categoriesdescription layer-one-margin"
                                    >
                                      <img src={layerOneCategory.icon} alt="" />
                                      <p>-</p>
                                      <p>{layerOneCategory.category}</p>
                                    </label>
                                    <Form.Check
                                      checked={selectedCategory?.includes(
                                        layerOneCategory.id
                                      )}
                                      aria-label={index}
                                      value={layerOneCategory.id}
                                      onChange={() =>
                                        toggleFormCheck(layerOneCategory.id)
                                      }
                                      id={layerOneCategory.category}
                                    />
                                  </div>
                                  <div>
                                    {layerOneCategory.children &&
                                      layerOneCategory.children.length > 0 &&
                                      layerOneCategory.children.map(
                                        (layerTwoCategory, index) => (
                                          <div key={layerTwoCategory.id}>
                                            <div className="categorieslist">
                                              <label
                                                htmlFor={
                                                  layerTwoCategory.category
                                                }
                                                className="categoriesdescription layer-two-margin"
                                              >
                                                <img
                                                  src={layerTwoCategory.icon}
                                                  alt=""
                                                />
                                                <p>- -</p>
                                                <p>
                                                  {layerTwoCategory.category}
                                                </p>
                                              </label>
                                              <Form.Check
                                                checked={selectedCategory?.includes(
                                                  layerTwoCategory.id
                                                )}
                                                aria-label={index}
                                                value={layerTwoCategory.id}
                                                onChange={() =>
                                                  toggleFormCheck(
                                                    layerTwoCategory.id
                                                  )
                                                }
                                                id={layerTwoCategory.category}
                                              />
                                            </div>
                                            <div>
                                              {layerTwoCategory.children &&
                                                layerTwoCategory.children
                                                  .length > 0 &&
                                                layerTwoCategory.children.map(
                                                  (
                                                    layerThreeCategory,
                                                    index
                                                  ) => (
                                                    <div
                                                      key={
                                                        layerThreeCategory.id
                                                      }
                                                    >
                                                      <div className="categorieslist">
                                                        <label
                                                          htmlFor={
                                                            layerThreeCategory.category
                                                          }
                                                          className="categoriesdescription layer-three-margin"
                                                        >
                                                          <img
                                                            src={
                                                              layerThreeCategory.icon
                                                            }
                                                            alt=""
                                                          />
                                                          <p>- - -</p>
                                                          <p>
                                                            {
                                                              layerThreeCategory.category
                                                            }
                                                          </p>
                                                        </label>
                                                        <Form.Check
                                                          checked={selectedCategory?.includes(
                                                            layerThreeCategory.id
                                                          )}
                                                          aria-label={index}
                                                          value={
                                                            layerThreeCategory.id
                                                          }
                                                          onChange={() =>
                                                            toggleFormCheck(
                                                              layerThreeCategory.id
                                                            )
                                                          }
                                                          id={
                                                            layerThreeCategory.category
                                                          }
                                                        />
                                                      </div>
                                                      <div>
                                                        {layerThreeCategory.children &&
                                                          layerThreeCategory
                                                            .children.length >
                                                            0 &&
                                                          layerThreeCategory.children.map(
                                                            (
                                                              layerFourCategory,
                                                              index
                                                            ) => (
                                                              <div
                                                                key={
                                                                  layerFourCategory.id
                                                                }
                                                              >
                                                                <div className="categorieslist">
                                                                  <label
                                                                    htmlFor={
                                                                      layerFourCategory.category
                                                                    }
                                                                    className="categoriesdescription layer-four-margin"
                                                                  >
                                                                    <img
                                                                      src={
                                                                        layerFourCategory.icon
                                                                      }
                                                                      alt=""
                                                                    />
                                                                    <p>
                                                                      - - - -
                                                                    </p>
                                                                    <p>
                                                                      {
                                                                        layerFourCategory.category
                                                                      }
                                                                    </p>
                                                                  </label>
                                                                  <Form.Check
                                                                    checked={selectedCategory?.includes(
                                                                      layerFourCategory.id
                                                                    )}
                                                                    aria-label={
                                                                      index
                                                                    }
                                                                    value={
                                                                      layerFourCategory.id
                                                                    }
                                                                    onChange={() =>
                                                                      toggleFormCheck(
                                                                        layerFourCategory.id
                                                                      )
                                                                    }
                                                                    id={
                                                                      layerFourCategory.category
                                                                    }
                                                                  />
                                                                </div>
                                                                <div>
                                                                  {layerFourCategory.children &&
                                                                    layerFourCategory
                                                                      .children
                                                                      .length >
                                                                      0 &&
                                                                    layerFourCategory.children.map(
                                                                      (
                                                                        layerFiveCategory,
                                                                        index
                                                                      ) => (
                                                                        <div
                                                                          key={
                                                                            layerFiveCategory.id
                                                                          }
                                                                        >
                                                                          <div className="categorieslist">
                                                                            <label
                                                                              htmlFor={
                                                                                layerFiveCategory.category
                                                                              }
                                                                            >
                                                                              <div className="categoriesdescription layer-four-margin">
                                                                                <img
                                                                                  src={
                                                                                    layerFiveCategory.icon
                                                                                  }
                                                                                  alt=""
                                                                                />
                                                                                <p>
                                                                                  -
                                                                                  -
                                                                                  -
                                                                                  -
                                                                                  -
                                                                                </p>
                                                                                <p>
                                                                                  {
                                                                                    layerFiveCategory.category
                                                                                  }
                                                                                </p>
                                                                              </div>
                                                                            </label>

                                                                            <Form.Check
                                                                              checked={selectedCategory?.includes(
                                                                                layerFiveCategory.id
                                                                              )}
                                                                              aria-label={
                                                                                index
                                                                              }
                                                                              value={
                                                                                layerFiveCategory.id
                                                                              }
                                                                              onChange={() =>
                                                                                toggleFormCheck(
                                                                                  layerFiveCategory
                                                                                )
                                                                              }
                                                                              id={
                                                                                layerFiveCategory.category
                                                                              }
                                                                            />
                                                                          </div>
                                                                        </div>
                                                                      )
                                                                    )}
                                                                </div>
                                                              </div>
                                                            )
                                                          )}
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="categories-footer mb-5">
                <div className="featured-image">
                  <h6>{t("Featured_Image")}</h6>
                  <p className="imp-info-text">{t("upload_per_product")}</p>

                  <div className="overview-card image-upload padding-10">
                    <label htmlFor="uploadImage">
                      <i className="fas fa-cloud-upload"></i>
                      <h6> {t("Drop_image_here")}</h6>
                      <p>
                        {t("Or_Browse_Images")}
                        <span className="imp-info-text"> {t("here")}</span>{" "}
                      </p>
                      <p className="imp-info-text">{t("Images_must_have")}</p>
                    </label>
                    <input
                      type="file"
                      id="uploadImage"
                      name="otherImages"
                      accept="image/*"
                      onChange={onSelectFile}
                      multiple
                      required
                    />
                    <div className="d-flex image-container">
                      {selectedImages &&
                        selectedImages.map((image, index) => {
                          return (
                            <div key={index} className="send-image">
                              <img src={image} alt="" className="image" />

                              <div className="bin-icon">
                                <i
                                  className="fas fa-trash-alt"
                                  onClick={() => {
                                    setSelectedImages(
                                      selectedImages?.filter((e) => e !== image)
                                    );
                                    handleImageDelete(index);
                                  }}
                                ></i>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="categories-footer-button">
                {!loader ? (
                  <button className="btn btn-danger" onClick={handleSubmit}>
                    {t("Submit")}
                  </button>
                ) : (
                  <button>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </button>
                )}
              </div>
            </main>
          )}
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(SellersNewProducts, ["SELLER"]);
