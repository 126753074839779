import React from "react";

import "./AliexpressOrderSuccess.css";

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

const AliexpressOrderSuccuss = () => {
  return (
    <div>
      {" "}
      <Header />\
      <div className="order-success-container">
        <div className="order-success-card">
          <h1 className="order-success-title">Thank You!</h1>
          <p className="order-success-message">
            Your order has been placed successfully.
          </p>
          <div className="order-success-icon">&#10003;</div>{" "}
          {/* This is a checkmark symbol */}
          <p className="order-success-details">
            You will receive an email confirmation shortly.
          </p>
          <Link to="/" className="order-success-button">
            Go to Home
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AliexpressOrderSuccuss;
