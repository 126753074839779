import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";

import "./Authentication.css";

import toast, { Toaster } from "react-hot-toast";

import SiteLogo from "../../assets/img/logo.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import { GlobalContext } from "../../components/utils/GlobalState";
import { useTranslation } from "react-i18next";
import { useEventTag } from "../../components/hooks/useEventTag.js";
import { toastOptions } from "../../components/helpers/functionHelpers.js";
import { axiosInstance } from "../../components/baseUrl.jsx";

const Login = () => {
  const { t } = useTranslation();
  const { setUser } = useContext(GlobalContext);
  const { handleEventTag } = useEventTag();
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  console.log("phoneNumber", phoneNumber);

  const navigate = useNavigate();

  const handleRegisterMoMoUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userDetails = {
        firstName: "",
        LastName: "",
        email: "",
        phoneNumber: phoneNumber,
        role: "BUYER",
        password: "",
        hearAboutUs: "",
      };
      const {
        data: { data },
      } = await axiosInstance.post(`/ipg/user`, userDetails);

      setUser(data.user);
      localStorage.setItem("user", true);
      localStorage.setItem("token", data.token);
      setLoading(false);
      navigate("/");
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      if (error?.response?.data?.errors[0].message) {
        toast.error(error?.response?.data?.errors[0].message, toastOptions);
        handleEventTag(
          `e_momo_userReg_${error.response.data.errors[0].message}`
        );
      } else {
        handleEventTag(`e_momo_userReg`);
        toast.error(`Network error!`, toastOptions);
      }
    }
  };

  return (
    <>
      <div>
        <Toaster />
        <section className="auth">
          <div className="auth-lhs">
            <div className="auth-lhs-header">
              <Link to="/">
                <img className="site-logo" src={SiteLogo} alt="" />
              </Link>
            </div>
          </div>
          <div className="auth-rhs">
            <div className="auth-rhs-header d-flex justify-content-between">
              <img className="site-logo mobile-only" src={SiteLogo} alt="" />
              <p>
                {t("Dont_have_an_account")}?{" "}
                <Link to="/registration" className="user-link">
                  {t("Register")}
                </Link>
              </p>
            </div>
            <div className="auth-rhs-content d-flex align-items-center">
              <div className="rhs-inner">
                <h2 className="mb-4">{t("Login_to_continue")}</h2>
                <div className="auth-account-wrap">
                  <form className="auth-form" onSubmit={handleRegisterMoMoUser}>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        {t("Enter MoMo Phone number")}
                      </label>
                      <PhoneInput
                        onChange={setPhoneNumber}
                        value={phoneNumber}
                        name="phoneValue"
                        className="form-control"
                        defaultCountry="NG"
                      />
                    </div>

                    {loading ? (
                      <button type="submit" className="btn btn-danger">
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-danger">
                        {t("Login")}
                      </button>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
