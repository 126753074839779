import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import SellersSidebar from "../SellersComponent/SellerSideBar.jsx";
import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { GlobalContext } from "../../../../components/utils/GlobalState";
import CardOverviewLoader from "../../../../components/SkeletonLoader/CardOverviewLoader.js";
import TableSkeleton from "../../../../components/SkeletonLoader/TableSkeleton.js";
import Pagination from "react-responsive-pagination";
import SellersHeader from "../SellersComponent/SellersHeader.jsx";
import { useGetSellersOrder } from "../../../../components/hooks/useGetSellersOrder.js";
import {
  numberWithCommas,
  Capitalize,
} from "../../../../components/helpers/functionHelpers.js";
import { convertDateFormat } from "../../../../components/helpers/convertDate.js";
import cookies from "js-cookie";
import { Toaster } from "react-hot-toast";
import { CurrencyExchangeContext } from "../../../../components/utils/CurrencyExchangeState.js";

const SellersOrder = () => {
  const { t } = useTranslation();
  const { user, userLoading } = useContext(GlobalContext);
  const { convertCurrency } = useContext(CurrencyExchangeContext);
  const [isActive, setIsActive] = useState(false);
  const selectedCurrency = cookies.get("currency") || "NGN";

  const navigate = useNavigate;

  const {
    handlePageChange,
    summary,
    orderLoading,
    totalPages,
    loading,
    sellerOrders,
    currentPage,
    search,
    handleChange,
    handleSubmit,
  } = useGetSellersOrder();

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  return (
    <>
      <Toaster />
      <div className="grid-container">
        <SellersHeader
          handleClick={handleClick}
          user={user}
          title="Orders"
          userLoading={userLoading}
        />
        <SellersSidebar isActive={isActive} handleClick={handleClick} />

        <main className="main">
          <h1 className="section-title">Activity Summary</h1>
          {orderLoading ? (
            <CardOverviewLoader />
          ) : (
            <div className="main-overview">
              <div className="overview-card">
                <div>
                  <h2>{t("Total_Orders")}</h2>
                  <div className="d-flex justify-content-between mt-4">
                    <h3>
                      {summary.total_pending_orders &&
                        numberWithCommas(
                          summary.total_pending_orders +
                            summary.total_shipped_orders +
                            summary.total_delivered_orders +
                            summary.total_confirmed_orders
                        )}{" "}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  <h2>{t("Ongoing_Orders")}</h2>
                  <div className="d-flex justify-content-between mt-4">
                    <h3>
                      {summary.total_pending_orders &&
                        numberWithCommas(
                          summary.total_pending_orders +
                            summary.total_shipped_orders +
                            summary.total_confirmed_orders
                        )}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="overview-card">
                <div>
                  <h2>{t("Completed_Orders")}</h2>
                  <div className="d-flex justify-content-between mt-4">
                    <h3>
                      {summary.total_delivered_orders &&
                        numberWithCommas(summary.total_delivered_orders)}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="section-title-ctn">
            <h1 className="section-title">{t("All_Orders")}</h1>
            <div className="header__search">
              <form onSubmit={handleSubmit}>
                <div className="custom__search">
                  <button type="submit">
                    {" "}
                    <i className="fas fa-search"></i>
                  </button>
                  <input
                    type="text"
                    className="form-control custom-style"
                    id=""
                    placeholder="Search orders, order status..."
                    value={search}
                    onChange={handleChange}
                  />
                </div>
              </form>
            </div>
          </div>

          {loading ? (
            <TableSkeleton />
          ) : (
            <>
              {sellerOrders && sellerOrders.length > 0 ? (
                <div className="main-overview">
                  <div className="overview-card no-padding">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">{t("Date")}</th>
                            <th scope="col">{t("Order_No")}</th>
                            <th scope="col">{t("Product_Info")}</th>
                            <th scope="col">{t("Cost")}</th>
                            <th scope="col">{t("Status")}</th>
                            <th scope="col">{t("Action")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {sellerOrders &&
                            sellerOrders.map((orders, index) => (
                              <tr key={index}>
                                <td>{convertDateFormat(orders.createdAt)}</td>
                                <td>{orders.orderNumber}</td>
                                <td>
                                  <p>
                                    <Link
                                      to={`/seller/order-info/${orders.id}`}
                                    >
                                      {orders.productName
                                        ? Capitalize(orders.productName)
                                        : orders.product
                                        ? Capitalize(orders.product.productName)
                                        : " "}
                                    </Link>
                                  </p>
                                </td>
                                <td>
                                  {selectedCurrency}{" "}
                                  {orders &&
                                    numberWithCommas(
                                      convertCurrency(
                                        orders.cost,
                                        orders?.currencyType,
                                        selectedCurrency
                                      )
                                    )}
                                </td>
                                <td>
                                  {orders.status === "PENDING" && (
                                    <div className="text-warning ">
                                      {t("Pending")}
                                    </div>
                                  )}
                                  {orders.status === "PROCESSING" && (
                                    <div className="text-primary ">
                                      {t("Processing")}
                                    </div>
                                  )}
                                  {orders.status === "SHIPPED" && (
                                    <div className="text-info">
                                      {t("Shipped")}
                                    </div>
                                  )}
                                  {orders.status === "DELIVERED" && (
                                    <div className="text-success">
                                      {t("Delivered")}
                                    </div>
                                  )}
                                  {orders.status === "CANCELLED" && (
                                    <div className="text-danger">
                                      {t("Cancelled")}
                                    </div>
                                  )}
                                  {orders.status === "RECEIVED" && (
                                    <div className="text-secondary">
                                      {t("Received")}
                                    </div>
                                  )}
                                  {orders.status === "NOT RECEIVED" && (
                                    <div className="text-secondary">
                                      {t("Not_Received")}
                                    </div>
                                  )}
                                </td>
                                <td>
                                  <Link to={`/seller/order-info/${orders.id}`}>
                                    {t("View")}
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {search ? (
                    <div className="empty-state">
                      <h4>{t("No_results_found")}</h4>
                      <p>{t("No_order_matched")}</p>
                    </div>
                  ) : (
                    <div className="empty-state">
                      <h3>{t("There_are_no_orders")}</h3>
                      <p>{t("All_your_order")}</p>
                    </div>
                  )}
                </>
              )}

              <Pagination
                current={currentPage}
                total={totalPages}
                onPageChange={handlePageChange}
                extraClassName="justify-content-start"
              />
            </>
          )}
        </main>
      </div>
    </>
  );
};

export default ProtectedRoutes(SellersOrder, ["SELLER"]);
