export function numberWithCommas(x) {
  if (x === undefined || x === null) {
    return "";
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const Capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export function separateCountryCode(phoneNumber) {
  // Regular expression to capture the country code with + and the main number
  const regex = /^(\+\d{1,3})?\s*([0-9().\-\s]*)$/;
  const match = phoneNumber.match(regex);

  if (match) {
    let countryCode = match[1];
    const mainNumber = match[2].trim();

    // If no country code is provided and 10 digits are detected, assume +1 (US)
    if (!countryCode) {
      if (mainNumber.replace(/[^0-9]/g, "").length === 10) {
        countryCode = "+1";
      } else {
        countryCode = "";
      }
    }

    return { countryCode, mainNumber };
  } else {
    return { countryCode: "", mainNumber: phoneNumber };
  }
}

export function calculateTwoPercent(number) {
  const result = number * 0.03;
  return Number(result.toFixed(2));
}

export function addTwoPercent(number) {
  const twoPercent = number * 0.03;
  const total = number + twoPercent;
  return Number(total.toFixed(2));
}

export function sumTwoNumbers(a, b) {
  const sum = Number(a) + Number(b);
  return sum.toFixed(2);
}

export function sumThreeNumbers(a, b, c) {
  const sum = Number(a) + Number(b) + Number(c);
  return sum.toFixed(2);
}

export const toastOptions = {
  duration: 4000,
  style: {
    background: "#353434",
    color: "#fff",
  },
};

export const ensurePlus = (value) => {
  if (value[0] !== "+") {
    return `+${value}`;
  }
  return value;
};

export const removeFirstPlus = (number) => {
  let numStr = String(number);

  if (numStr.startsWith("+")) {
    return numStr.slice(1);
  }

  return numStr;
};

export const calculateShippingFee = (cart) => {
  if (!cart || cart.length < 1) {
    return 0;
  }
  const uniqueIds = new Set();

  cart.forEach((item) => {
    uniqueIds.add(item.productId);
  });

  return uniqueIds.size * 2;
};

export const currencies = {
  result: "success",
  documentation: "https://www.exchangerate-api.com/docs",
  terms_of_use: "https://www.exchangerate-api.com/terms",
  time_last_update_unix: 1726772101,
  time_last_update_utc: "Thu, 19 Sep 2024 18:55:01 +0000",
  time_next_update_unix: 1726772401,
  time_next_update_utc: "Thu, 19 Sep 2024 19:00:01 +0000",
  base_code: "USD",
  conversion_rates: {
    USD: 1,
    AED: 3.6725,
    AFN: 69.42963979,
    ALL: 89.32022286,
    AMD: 387.44688201,
    ANG: 1.79,
    AOA: 944.31250069,
    ARS: 963.33,
    AUD: 1.46752731,
    AWG: 1.79,
    AZN: 1.70120393,
    BAM: 1.75418366,
    BBD: 2,
    BDT: 119.54300436,
    BGN: 1.75404499,
    BHD: 0.376,
    BIF: 2905.05433041,
    BMD: 1,
    BND: 1.29293627,
    BOB: 6.93201046,
    BRL: 5.46241178,
    BSD: 1,
    BTN: 83.66940895,
    BWP: 13.2239695,
    BYN: 3.23968545,
    BZD: 2,
    CAD: 1.35594953,
    CDF: 2842.00710978,
    CHF: 0.8470552,
    CLP: 929.67582203,
    CNY: 7.07034565,
    COP: 4194.77006486,
    CRC: 518.10407466,
    CUP: 24,
    CVE: 98.89666362,
    CZK: 22.5024687,
    DJF: 177.721,
    DKK: 6.69550937,
    DOP: 60.07300005,
    DZD: 132.30717663,
    EGP: 48.52783691,
    ERN: 15,
    ETB: 115.62770014,
    EUR: 0.89670394,
    FJD: 2.2041215,
    FKP: 0.7537674,
    FOK: 6.69548761,
    GBP: 0.7535965,
    GEL: 2.72140647,
    GGP: 0.7537674,
    GHS: 15.79236972,
    GIP: 0.7537674,
    GMD: 70.65219735,
    GNF: 8663.10816065,
    GTQ: 7.73796262,
    GYD: 209.35079648,
    HKD: 7.79430834,
    HNL: 24.8319454,
    HRK: 6.75769203,
    HTG: 131.78867343,
    HUF: 353.50049741,
    IDR: 15235.14330145,
    ILS: 3.7586596,
    IMP: 0.7537674,
    INR: 83.66852305,
    IQD: 1310.19603335,
    IRR: 42087.88798635,
    ISK: 136.80216788,
    JEP: 0.7537674,
    JMD: 157.27808064,
    JOD: 0.709,
    JPY: 142.75343712,
    KES: 129.11653055,
    KGS: 84.49097438,
    KHR: 4065.78080404,
    KID: 1.46744352,
    KMF: 441.24580857,
    KRW: 1326.83651154,
    KWD: 0.30507801,
    KYD: 0.833333,
    KZT: 479.00532382,
    LAK: 21944.67105433,
    LBP: 89500,
    LKR: 303.40906592,
    LRD: 200.01879475,
    LSL: 17.49164644,
    LYD: 4.76437666,
    MAD: 9.71321627,
    MDL: 17.43001941,
    MGA: 4529.36075187,
    MKD: 55.32250284,
    MMK: 2102.52216198,
    MNT: 3401.25426238,
    MOP: 8.02813626,
    MRU: 39.63817343,
    MUR: 45.82084388,
    MVR: 15.46650057,
    MWK: 1743.39116388,
    MXN: 19.28487947,
    MYR: 4.23349927,
    MZN: 63.93459241,
    NAD: 17.49164644,
    NGN: 1780,
    NIO: 36.83134963,
    NOK: 10.49351097,
    NPR: 133.87105431,
    NZD: 1.60098452,
    OMR: 0.384497,
    PAB: 1,
    PEN: 3.75693377,
    PGK: 3.93273125,
    PHP: 55.54649386,
    PKR: 278.19543603,
    PLN: 3.83132977,
    PYG: 7808.81452574,
    QAR: 3.64,
    RON: 4.46028382,
    RSD: 105.09686042,
    RUB: 93.00835349,
    RWF: 1350.21560071,
    SAR: 3.75,
    SBD: 8.49427816,
    SCR: 13.54747597,
    SDG: 512.12182494,
    SEK: 10.16218366,
    SGD: 1.29232369,
    SHP: 0.7537674,
    SLE: 22.68761299,
    SLL: 22687.6129751,
    SOS: 571.62425419,
    SRD: 30.04892575,
    SSP: 2892.22283666,
    STN: 21.97404669,
    SYP: 12870.87689882,
    SZL: 17.49164644,
    THB: 33.16694861,
    TJS: 10.63004988,
    TMT: 3.50080717,
    TND: 3.03515222,
    TOP: 2.32706303,
    TRY: 34.05651063,
    TTD: 6.78932467,
    TVD: 1.46744352,
    TWD: 31.9113294,
    TZS: 2718.28615099,
    UAH: 41.4641797,
    UGX: 3719.77471228,
    UYU: 41.03542731,
    UZS: 12728.63407423,
    VES: 36.7789,
    VND: 24647.15324257,
    VUV: 118.48891856,
    WST: 2.70525592,
    XAF: 588.32774476,
    XCD: 2.7,
    XDR: 0.73847004,
    XOF: 588.32774476,
    XPF: 107.02885469,
    YER: 250.41042206,
    ZAR: 17.4883713,
    ZMW: 26.32510087,
    ZWL: 13.9711,
  },
};
