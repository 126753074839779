import React, { useContext, useState } from "react";

import { useTranslation } from "react-i18next";

import { useRemoveProductFromCart } from "../../components/hooks/useRemoveProductFromCart";
import { useUpdateProductQuantityInCart } from "../../components/hooks/useUpdateProductQuantityInCart";
import { CurrencyExchangeContext } from "../../components/utils/CurrencyExchangeState";

const CartItems = ({ cartItems, selectedCurrency }) => {
  const { t } = useTranslation();
  const { convertCurrency } = useContext(CurrencyExchangeContext);
  const [showQuantity, setShowQuantity] = useState(false);
  const [quantityInput, setQuantityInput] = useState(false);
  const [qtyInput, setQtyInput] = useState("");
  const [show, setShow] = useState("");
  const selectProductQuantity = [1, 2, 3, 4];

  const { removeFromCart } = useRemoveProductFromCart();
  const { updateQuantity } = useUpdateProductQuantityInCart();

  const handleQuantityUpdate = (cartId) => {
    if (qtyInput) {
      updateQuantity(cartId, qtyInput);
    }
    setQuantityInput(false);
    setQtyInput("");
  };

  return (
    <>
      {" "}
      {cartItems?.cart && cartItems?.cart.length > 0 && (
        <div className="col-12">
          <div className="over-card">
            <h2 className="user__title">Products from TOFA</h2>
            <div className="user-product-ctn">
              {cartItems.cart &&
                cartItems?.cart?.map((product) => (
                  <div className="user-product-wrap" key={product.id}>
                    <div className="user-product-details">
                      <div className="user-product-img">
                        <img
                          src={product.product?.productImages[0]?.image}
                          alt="product"
                        />
                      </div>
                      <p>{t("Estimated_delivery")}</p>
                      <p>
                        {product.product.minDuration &&
                          product.product.minDuration}{" "}
                        -
                        {product.product.maxDuration &&
                          product.product.maxDuration}
                      </p>
                    </div>
                    <div className="user-product-name">
                      <div className="product-name">
                        <p>
                          {product.product?.productName &&
                            product.product.productName}
                        </p>
                      </div>
                      <div className="product-amount">
                        <p>
                          {selectedCurrency}{" "}
                          {product.totalPrice &&
                            convertCurrency(
                              product?.totalPrice,
                              "USD",
                              selectedCurrency
                            )}
                        </p>

                        <div className="product-count">
                          {quantityInput && show === product.id ? (
                            <div className="qty-input-ctn">
                              <input
                                type="number"
                                name="qtyInput"
                                value={qtyInput}
                                onChange={(e) => setQtyInput(e.target.value)}
                              />
                              <button
                                onClick={() => handleQuantityUpdate(product.id)}
                              >
                                {t("Update")}
                              </button>
                            </div>
                          ) : (
                            <div
                              className="cttn"
                              onClick={() =>
                                setShowQuantity(
                                  showQuantity === product.id
                                    ? null
                                    : product.id
                                )
                              }
                            >
                              <p>
                                Qty: {product.quantity && product.quantity}{" "}
                              </p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                />
                              </svg>
                              {showQuantity === product.id && (
                                <ul className="qty-dropdown">
                                  <li
                                    onClick={() => removeFromCart(product.id)}
                                  >
                                    0 ({t("Delete")})
                                  </li>
                                  {selectProductQuantity.map((qty) => (
                                    <li
                                      onClick={() => {
                                        updateQuantity(product.id, qty);
                                      }}
                                      key={qty}
                                    >
                                      {qty}
                                    </li>
                                  ))}
                                  <li
                                    onClick={(e) => {
                                      setShow(product.id);
                                      setQuantityInput(true);
                                    }}
                                  >
                                    5+
                                  </li>
                                </ul>
                              )}
                            </div>
                          )}
                          <p onClick={() => removeFromCart(product.id)}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                              />
                            </svg>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CartItems;
