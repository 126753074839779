import React from "react";
import { Link } from "react-router-dom";

import Logo from "../../assets/img/logo-white.png";

const Sidebar = ({ isActive, handleClick }) => {
  return (
    <>
      <aside id="seller" className={isActive ? "media-sidenav" : "sidenav"}>
        <div className="sidenav__close-icon" onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="w-6 h-6"
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>

        <div className="d-logo-area mb-3">
          <img className="logo" src={Logo} alt="TOFA" />
        </div>

        <ul className="sidenav__list">
          <Link className="sidenav-link" to="/momo/overview">
            <li className="sidenav__list-item">
              <i className="fas fa-home list_icon"></i>
              Overview
            </li>
          </Link>

          <Link className="sidenav-link" to={`/momo/all-referrals`}>
            <li className="sidenav__list-item">
              <i className="fas fa-store list_icon"></i>
              All referrals
            </li>
          </Link>

          <Link className="sidenav-link" to="/momo/all-transactions">
            <li className="sidenav__list-item">
              <i className="fas fa-shopping-cart list_icon"></i>
              All Transaction
            </li>
          </Link>
        </ul>
      </aside>
    </>
  );
};

export default Sidebar;
