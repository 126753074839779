import React, { useState } from "react";
import Sidebar from "../../../components/MoMoComponent/Sidebar";
import MoMoHeader from "../../../components/MoMoComponent/MoMoHeader";
import { useGetMoMoReferralCode } from "../../../components/hooks/useGetMoMoReferralCode";
import { Link } from "react-router-dom";
import PaginationComponent from "../../Dashboard/BuyersDashboard/BuyersComponent/Pagination";
import TableSkeleton from "../../../components/SkeletonLoader/TableSkeleton";
import SearchInput from "../../Dashboard/BuyersDashboard/BuyersComponent/SearchInput";

const AllReferral = () => {
  const [isActive, setIsActive] = useState(false);
  const {
    allReferral,
    currentPage,
    search,
    referralData,
    setCurrentPage,
    setSearch,
    totalItems,
    loading,
    ITEMS_PER_PAGE,
  } = useGetMoMoReferralCode();

  const handleClick = () => {
    setIsActive((current) => !current);
  };

  return (
    <div>
      <div className="grid-container">
        <MoMoHeader title="All Referrals" handleClick={handleClick} />
        <Sidebar isActive={isActive} handleClick={handleClick} />
        <main className="main">
          <div className="main-overview">
            <div className="overview-card">
              <div>
                <h2>All Referrals</h2>

                <div className="d-flex justify-content-between mt-4">
                  <h3>1000</h3>
                </div>
              </div>
            </div>
            <div className="overview-card">
              <div>
                <h2>Total Trnsactions</h2>

                <div className="d-flex justify-content-between mt-4">
                  <h3>120</h3>
                </div>
              </div>
            </div>
            <div className="overview-card">
              <div>
                <h2>Total profit</h2>

                <div className="d-flex justify-content-between mt-4">
                  <h3>$23,000</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="section-title-ctn">
            <div className="header__search">
              <SearchInput
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
                placeholder="Search referral code"
              />
            </div>
          </div>

          {loading ? (
            <TableSkeleton />
          ) : (
            <>
              {allReferral && allReferral.length > 0 ? (
                <div className="main-overview">
                  <div className="overview-card no-padding">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Referral code</th>
                            <th scope="col">No of transaction</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {referralData &&
                            referralData.map((referral, index) => (
                              <tr key={index}>
                                <td>
                                  {referral.refererCode && referral.refererCode}
                                </td>
                                <td>
                                  {referral.orders && referral.orders.length}
                                </td>
                                <td>
                                  <Link
                                    to={`/seller/order-info/${referral.id}`}
                                  >
                                    View details
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {search && (
                    <div className="empty-state">
                      <h4>No result found</h4>
                    </div>
                  )}
                </>
              )}

              <PaginationComponent
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </>
          )}
        </main>
      </div>
    </div>
  );
};

export default AllReferral;
