import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";

const PrivacyPolicy = () => {
  const companyWebsite = process.env.REACT_APP_COMPANY_WEBSITE;
  const companyUrl = process.env.REACT_APP_COMPANY_URL;

  return (
    <div>
      {" "}
      <Header />
      <div className="container terms_ctn py-4">
        <h1 className="">Privacy Policy</h1>
        <p>
          Note: this <a href={companyWebsite}>{companyUrl}</a> Privacy Policy
          applies to the collection, use and disclosure of information in
          connection with the products and services offered by{" "}
          <a href={companyWebsite}>{companyUrl}</a>. Please refer to
          Marketplase.tradersofafrica.com Privacy Policy for the privacy policy
          that governs the collection, use and disclosure of information in
          connection with the products and services offered by{" "}
          <a href={companyWebsite}>{companyUrl}</a>.
        </p>
        <p>
          We at <a href={companyWebsite}>{companyUrl}</a>. ("we", "us")
          recognize the importance of privacy and confidentiality of personal
          information. <a href={companyWebsite}>{companyUrl}</a>. (the
          "Platform") is a B2B platform which connects and facilitates sales and
          purchases of products and/or services between suppliers (or "Sellers")
          and buyers (or "Buyers"). The Platform is for business to business
          transactions and therefore is only made available for use by
          businesses and not for individual or personal use. This Privacy Policy
          sets out the ways in which <a href={companyWebsite}>{companyUrl}</a>.
          collect, use and disclose information in connection with its operation
          of the Platform including personal information about Platform visitors
          and representatives and employees of Buyers and Sellers ("you"). This
          Privacy Policy also applies to use of our Platform via a mobile device
          either through mobile applications or mobile optimized websites. For
          details of the personal information that we collect when you use or
          interact with other <a href={companyWebsite}>{companyUrl}</a>.
          services, please refer to the applicable Privacy Policy services.
        </p>
        <p>
          If you have any questions or concerns about the use of your personal
          information, then please contact us using the contact details provided
          at section M."HOW TO CONTACT US" the bottom of this Privacy Policy.
          All capitalized terms not defined in this document shall have the
          meanings ascribed to them in the Terms of Use for the Platform, which
          can be found here.
        </p>
        <div className="my-5 terns">
          <div id="our-services">
            <h1>A. COLLECTION OF INFORMATION </h1>
            <p>
              The personal information that we may collect about you broadly
              falls into the following categories:
            </p>
            <h5>Information you provide to us </h5>
            <p>
              If you are a representative or employee of any Buyer or Seller or
              a sole trader Buyer or Seller:
            </p>
            <ul className="list___items">
              <li>
                You will be asked to provide certain contact information that is
                necessary for the registering for a Platform account on behalf
                of a Buyer or Seller, including name, address, phone number,
                email address, job title and department;
              </li>
              <li>
                You will be asked to provide certain identity information, tax
                registration and/or related information about your business,
                such as your company name, business type and industry, corporate
                registration details and information about your business
                license;
              </li>
              <li>
                If you are a Seller, we will also ask you to provide details
                about the goods and products that you intend to sell via the
                Platform and details about your sales/transactions on
                Marketplase.tradersofafrica.com;
              </li>
              <li>
                If you are a Buyer, we will also ask you to provide details or
                preferences about the goods and products that you may be
                interested in buying via the Platform and any details or
                preferences relevant to your purchases;
              </li>
              <li>
                If you are successfully certified by
                Marketplase.tradersofafrica.com as a blogger or an influencer,
                in addition to the information mentioned above, we may also
                collect your live stream videos, your social media account names
                and profile photos, and posts or comments made by other users of
                the Platform;{" "}
              </li>
              <li>
                In connection with the facilitation of transactions, purchases
                and payments over the Platform, you will be asked to provide
                certain payment information. This might include bank account
                numbers, billing and delivery information, credit/debit card
                numbers, expiration dates and security code and tracking
                information from cheques or money orders to facilitate the sale
                and purchase as well as the settlement of purchase price of the
                products or services transacted on or procured through the
                Platform;
              </li>
              <li>
                We will process any information provided to us in connection
                with disputes or to support complaints in connection with the
                Platform or goods and/or services purchased via the Platform.
                Please note, Buyers andor Sellers may submit complaints
                regarding the products and services on the Platform and/or
                disputes between the Buyers and Sellers to the Platform and
                under such circumstances, we will process data provided to us by
                Buyers and/or Sellers that is relevant to the disputes or
                complaints submitted. This may include health data of endusers
                (which may include minors as applicable) of products purchased
                on the Platform to the extent relevant to the disputes or
                complaints that are being reviewed by the Platform and to the
                extent that the individual(s) has provided their explicit
                consent to their data being shared with us for the purposes of
                handling the complaints and/or settling the disputes.
              </li>
              <li>
                Information you submit to the Platform for publication may be
                made publicly available on the Platform and therefore accessible
                by any internetuser. You should exercise caution when deciding
                what information, you submit to us for publication.
              </li>
              <li>
                In order to facilitate your use of the services available on the
                Platform (including on websites and/or mobile applications), we
                may request for access to, and you may provide us with, contents
                stored in your devices including but not limited to contacts in
                address book, photos, videos and/or other files. If you are
                using our communication tools on the Platform (particularly in
                case of voice communication), we may require access to your
                camera and/or microphone, and may conduct voice recordings.
              </li>
            </ul>
            <h5>Information that we collect automatically </h5>
            <ul className="list___items">
              <li>
                If you contact us (such as via our customer services department
                chat service, telephone or email), we may record a copy of your
                correspondence and may ask for additional information to verify
                your identity.
              </li>
              <li>
                We will collect contact details and other information of users
                from publicly available sources for the purposes of verifying
                the users and performing due diligence on the users.
              </li>
              <li>
                We will collect details of user activities, transactions and
                interactions on the Platform including information relating to
                the types and specifications of the products and services
                purchased, pricing and delivery information, dispute and
                complaints records, communications information between users any
                information disclosed in any discussion forum.{" "}
              </li>
              <li>
                From time to time, we may also collect information about the
                existing and prospective users, during or in connection with
                trade shows, industry events and/or other functions. Including
                representative contact names, addresses, phone numbers, fax
                numbers and email addresses.
              </li>
              <li>
                If you visit our Platform, we will automatically collect certain
                information from your device. In some countries, including
                countries in the European Economic Area, this information may be
                considered as personal information under applicable data
                protection laws. Specifically, this information may include IP
                addresses, device type, unique device identification numbers,
                browser type, geographic location, browsing patterns and details
                of how you have interacted with our Platform and the goods and
                services available on it. In addition, we gather statistical
                information about the Platform and visitors to the Platform
                including, browser software, operating system, software and
                hardware attributes, pages viewed, number of sessions and unique
                visitors. Collecting this information enables us to better
                understand the visitors who come to our Platform, where they
                come from, and what content is of interest to them.
              </li>
              <li>
                We use this information for our internal analytics purposes and
                to improve the quality and relevance of our Platform. Some of
                this information may be collected using cookies and similar
                tracking technology, as explained further under the section E.
                “COOKIES” below.
              </li>
            </ul>
            <h5>Information that we receive from third parties</h5>
            <ul className="list___items">
              <li>
                We may receive personal information about you from social media
                platforms if you choose to register for a Platform account via a
                social media account. Exactly what information we receive will
                depend on your privacy settings with the applicable platform,
                but it would typically include basic public profile information
                such as:
              </li>
              <li>Your user’s name or nickname</li>
              <li> Your profile pictures</li>
              <li>Country</li>
              <li>Company name</li>
              <li> Contact details </li>
              <li>
                We may receive personal information about you from third parties
                that are engaged by us to assist with providing verification
                services, and conducting suitable money laundering and KYC
                (know-your customer) checks on users, and to improve our
                marketing efforts. This may include your name, email address,
                company details and contact information for company
                representatives.
              </li>
              <li>
                If you choose to participate in reviewing or rating products
                and/or services on Marketplase.tradersofafrica.com, we may
                receive personal information about you from third parties that
                work with us to conduct reviews into our services and/or rate
                us.
              </li>
            </ul>
            <h1>B. USE OF PERSONAL INFORMATION</h1>
            <p>
              We collect and use your personal information for the following
              purposes:
            </p>
            <ul className="list___items">
              <li>
                Verifying your identity and conducting security, KYC
                (know-your-customer) and anti-money laundering diligence and
                background checks in order to set up user accounts;
              </li>
              <li>
                Verifying your eligibility to register as a Platform user in
                accordance with our Terms of Use here;
              </li>
              <li>
                Setting up / administering user accounts, including providing
                log-in IDs and developing a Platform profile;
              </li>
              <li>
                Providing users with customer service support; responding to
                queries, feedback, and managing claims and/or disputes;
              </li>
              <li>
                Facilitating communication between Buyers and Sellers via the
                Platform, processing transactions and settlement between Buyers
                and Sellers made over the Platform, assessing fund withdrawal
                requests from Sellers;
              </li>
              <li>
                Providing logistics and warehousing services (including, without
                limitation, customs clearance declarations via the Platform);
              </li>
              <li>
                Facilitating customs clearance declarations / applications via
                the Platform;
              </li>
              <li>
                Assessing and monitoring account security and transaction risks
                of users of Marketplase.tradersofafrica.com, detecting and
                preventing fraud, money laundering and other security incidents;
              </li>
              <li>
                Sending and serving tailored marketing and advertising that we
                believe will be of interest to you based on the ways in which
                you use the Platform, your browsing records, and order history;
              </li>
              <li>
                Performing research or statistical analysis in order to improve
                the content and layout of the Platform, and to improve the
                product offerings and services on
                Marketplase.tradersofafrica.com, including, for example, using
                anonymized data for machine learning purposes;
              </li>
              <li>
                Identifying, developing and marketing and advertising products
                and services that we believe you will value, including across
                browsers and devices, in accordance with applicable laws.
                Cookies or other similar technologies may be used to provide you
                with advertising based upon your browsing activities and
                interests (see section E. "COOKIES" below). Where we are
                required by applicable law, we will seek your consent prior to
                sending you communications for marketing purposes;
              </li>
              <li>
                If you are a Buyer, we will share details of the last viewed
                products on the Platform to enable Sellers, their respective
                affiliates and/or their third-party services provider to contact
                you with details of products and/or services that might interest
                you. You can opt not to share this information with Sellers via
                the Buyer Privacy Settings
              </li>
              <li>
                When you use services that require access to contents stored in
                and/or functions on your devices (including but not limited to
                contacts in address book, photos, videos, microphone and/or
                camera), we use your photos, videos, contact information in your
                address book, and other personal information to respond to your
                requests, provide the requested services to you, and improve our
                services.
              </li>
              <li>
                We may also use your personal information for other purposes
                that are not incompatible with the purposes we have disclosed to
                you (such as archiving purposes in the public interest,
                scientific or historical research purposes, or statistical
                purposes) if and where this is permitted by applicable data
                protection laws.
              </li>
            </ul>
            <h1>C. DISCLOSURE OR SHARING OF PERSONAL INFORMATION</h1>
            <p>
              We may disclose (or provide access to) personal information to the
              following categories of recipients:
            </p>
            <ul className="list___items">
              <li>
                Other Platform Users where necessary in connection with the
                facilitation of purchases and communications made between you
                and the other users.
              </li>
              <li>
                Third party business partners, service providers and/or
                affiliates of Marketplase.tradersofafrica.com engaged by us or
                working with us to assist us to provide services to you or who
                otherwise process personal information for purposes described in
                this Privacy Policy or notified to you when we collect your
                personal information. Categories of these partners or service
                providers include:
              </li>
              <li>
                Members of Marketplase.tradersofafrica Group and their
                respective affiliates and/or their designated service providers
                that work with us to provide processing services such as
                software, tools, systems and messaging services for purposes
                described in this Privacy Policy;
              </li>
              <li>
                Our business partners (for example, in order for them to provide
                you with discounts or offers that may interest you);
              </li>
              <li>
                Marketing and advertising platforms, such as Google, Twitter,
                Facebook and Instagram, and providers of analytics services
                relating to users’behavior, in order to tailor the content, you
                see when visiting our Platform. These platforms may combine
                information they collect on our Platform with data on their
                platforms and data they collect from other websites or through
                other sources in order to conduct targeted advertising. The
                processing activities of these third party marketing platforms
                are governed by their own privacy policies, not this Privacy
                Policy;
              </li>
              <li>
                Payment service providers who collect and process personal
                information to assist with settling the payments for
                transactions or process withdrawal requests for Sellers. The
                activities of payment service providers may be governed by their
                own privacy policies, not this Privacy Policy;
              </li>
              <li>
                Credit risk assessment providers to conduct risk assessment on
                Sellers to determine whether a Seller can be permitted to make a
                fund withdrawal;
              </li>
              <li>
                Logistics partners for providing delivery services for Buyers,
                including return and exchange of products, and warehousing
                services for Sellers;
              </li>
              <li>Custom agents for customs clearances purposes;</li>
              <li>
                Cloud computing service providers to provide cloud storage
                services;
              </li>
              <li>
                Customer service providers to provide after-sale services;
              </li>
              <li>
                risk control service providers to assess the security of user’s
                accounts and transaction risks; and
              </li>
              <li>
                Third party rating / reviewing service providers to carry out
                reviews of our services with customers if you choose to
                participate in reviewing or rating
                Marketplase.tradersofafrica.com products and/or services;
              </li>
              <li>
                To our professional advisers, law enforcement agencies,
                insurers, government and regulatory and other organizations
                where we believe it is necessary to comply with applicable laws
                or to exercise, establish or defend our legal rights or protect
                your vital interests or those of any other person, we may also
                disclose and transfer your personal information or as otherwise
                required or permitted by applicable laws.
              </li>
              <li>
                To an actual or potential buyer (and its agents and advisers) in
                connection with any actual or proposed purchase, merger or
                acquisition of any part of our business, provided that we inform
                the buyer it must use your personal information only for the
                purposes disclosed in this Privacy Policy.
              </li>
              <li>To any other person with your consent to the disclosure.</li>
            </ul>
            <p>
              We may provide aggregated or anonymized data to third parties, but
              when we do so, the information we share is in a deidentified
              format that does not personally identify you. We have established
              relationships with third parties and websites to offer you
              products and services which we do not offer and such third parties
              and websites include the following affiliated platforms of
              Marketplase.tradersofafrica.com or their operators: We offer you
              access to these other parties and their websites either through
              the use of hyperlinks to these sites from our Platform or through
              offering “co-branded “sites in which both we and other parties
              share the same uniform resource locator (URL), domain name or
              pages within a domain name on the Internet. In some cases, you may
              be required to submit personal information to register or apply
              for products or services provided by such third parties or
              co-branded partners. This Privacy Policy does not apply to these
              third-party sites or co-branded sites. The privacy policies of
              those other parties may differ from ours, and we have no control
              over the information that you submit to those third parties. You
              should read the relevant privacy policy for those third-party
              sites and co-branded sites before responding to any offers,
              products or services advertised by those parties.
            </p>
            <h1>D. RETENTION </h1>
            <p>
              We retain your personal information as long as we have an ongoing
              legitimate business need to do so for example to provide services
              or products to you, or as required or permitted by applicable
              laws, such as tax and accounting laws. When we have no ongoing
              legitimate business need to process your personal information, we
              will either delete or anonymize it or, if this is not possible
              (for example, because your personal information has been stored in
              backup archives), then we will securely store your personal
              information and isolate it from any further processing until
              deletion is possible.
            </p>
            <h1>E. COOKIES</h1>
            <p>
              This section explains how we use cookies and similar technologies
              (we'll refer to them collectively as "cookies") to recognize you
              when you visit our Platform. It explains what these technologies
              are and why we use them, as well as your rights to control them.
            </p>
            <h5>What are cookies?</h5>
            <p>
              A cookie is a small amount of data that is sent to your browser
              and stored on your computers or mobile device’s hard drive. If you
              do not de-activate or erase the cookie, each time you use the same
              browser or mobile device to access the Platform, our web servers
              will be notified of your visit to the Platform and in turn we may
              have knowledge of your visit and the pattern of your usage.
            </p>
            <p>
              Cookies set by the website or app owner (in this case,
              Marketplase.tradersofafrica.com) are called "first party cookies".
              Cookies set by parties other than the website owner are called
              "third party cookies". Third party cookies enable third party
              features or functionality to be provided on or through the website
              (e.g., like advertising, interactive content and analytics). The
              parties that set these third party cookies can recognize your
              device both when it visits the website in question and also when
              it visits certain other websites.
            </p>
            <p>
              Cookies can also be categorized as session cookies (which are
              erased once you close the browser or shut down the device) and
              persistent cookies (which remain even after closing the browser or
              shutting down the device/application and which are activated each
              time you visit the Platform). The session cookies use encrypted
              data to authenticate you. The persistent cookies do not store
              account numbers or passwords. We use both session cookies and
              persistent cookies.
            </p>
            <h5>Why we use cookies? </h5>
            <p>
              We use first and third party cookies for several reasons. Some
              cookies are required for technical and/or security reasons in
              order for our Platform to operate, and we refer to these as
              "essential" or "strictly necessary" cookies. First party cookies
              are mainly served to enable basic functionality on our Platform;
              including web analytics cookies that help us understand how users
              use our web pages and how we can improve their use. For example,
              we use essential or strictly necessary cookies to enable us to
              remember goods added to your Marketplase.tradersofafrica.com
              shopping basket and to recognize you when you sign into your
              Marketplase.tradersofafrica.com account.
            </p>
            <p>
              In addition, we also use social media cookies. You may register
              for Platform membership and "share" Platform content through
              social media such as Facebook and Twitter. Sometimes we embed
              videos from websites like YouTube. These websites have their own
              privacy policies which govern their use of information, which you
              can find on the respective websites.
            </p>
            <p>
              We also use cookies to enable us to track and target the interests
              of Platform visitors to enhance the experience on our Platform by
              researching visiting patterns and conduct interest-based
              adverting; assisting our partners to track user visits to the
              Platforms; and to track progress and participation in promotions.
              We use first- and third-party cookies for these purposes.
            </p>
            <h5>What about other tracking technologies, like web beacons? </h5>
            <p>
              Cookies are not the only way to recognise or track visitors to our
              Platform. We may use other, similar technologies from time to
              time, like web beacons (sometimes called "tracking pixels" or
              "clear gifs"). These are tiny graphics files that contain a unique
              identifier that enable us to recognise when someone has visited
              our Platform or opened an e-mail that we have sent them.{" "}
            </p>
            <p>
              This allows us, for example, to monitor the traffic patterns of
              users from one page within our Platform to another, to deliver or
              communicate with cookies, to understand whether you have come to
              our Platform from an online advertisement displayed on a
              third-party website, to improve site performance, and to measure
              the success of e-mail marketing campaigns. In many instances,
              these technologies are reliant on cookies to function properly,
              and so declining cookies will impair their functioning.
            </p>
            <h5>How can I control cookies?</h5>
            <p>
              You can determine if and how a cookie will be accepted by
              configuring the privacy setting of the browser you are using to
              access the Platform or the "privacy setting" of your device. As
              the means by which you can refuse cookies through your web browser
              controls vary from browser-to-browser, you should visit your
              browser’s help menu for more information. If you adjust the
              privacy setting in the browser, your device will continue
              collecting data unless you adjust the privacy setting of the
              device, and vice versa.
            </p>
            <p>
              You have the right to decide whether to accept or reject cookies.
              If you choose to set your web browser controls to reject cookies,
              you may still use our website though your access to some
              functionality and areas of our website may be restricted.
            </p>
            <p>
              You can also control your cookie preferences though the Cookie
              Preferences Tool
              https://buyercentral.marketplase.tradersofafrica.com/privacy/cookie_setting.htm.
              In addition, most advertising networks offer you a way to opt out
              of targeted advertising. If you would like to find out more
              information, please visit http://www.aboutads.info/choices/ or
              http://www.youronlinechoices.com. Alternatively, you can follow
              the applicable links of the respective third party personalization
              cookie provided in the table above for details of how to opt out
              of use of particular advertising or personalization cookies.
            </p>
            <p>
              Certain features of the Platform depend on cookies. Please be
              aware that if you choose to block cookies, you may not be able to
              sign in or use those features, and preferences that are dependent
              on cookies may be lost.
            </p>
            <p>
              Google Analytics (More information) Our Platform uses Google
              Analytics, an internet analytics service provided by Google, Inc.
              ("Google"). Google's cookies allow us analyze use of the Platform
              by telling us which pages our users are viewing, which ones are
              most popular, what time of day our Platform are visited, if users
              have previously visited our Platform, from which website users are
              redirected to our Platform were and the like. The data generated
              by the cookie about your use of the Platform will be transmitted
              Google and stored by Google on servers in the United States.
            </p>
            <p className="mt-4">
              The Platform uses an anonymization tool of IP addresses so that
              the IP addresses are truncated within the territory of the Member
              States of the European Union and the other members of the
              agreement on the European Economic Area. The non-truncated IP
              address are only exceptionally transmitted to Google’s servers in
              the US and then truncated there.
            </p>
            <p>
              Google uses the data on behalf of Marketplase.tradersofafrica.com
              to evaluate your use of the Platform, to compile reports on
              website activity for the website operators and for other website
              activity and internet usage services. Google may disclose this
              information to third parties under the terms of the law or for
              further processing by third parties on behalf of Google. For more
              information about Google Analytics cookies, visit:
            </p>
            <ul className="list___items">
              <li>
                The Google Help pages and Privacy Policy: Google Privacy Policy:
                and
              </li>
              <li>The Google Analytics Help Center:</li>
            </ul>
            <p>
              https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
            </p>
            <p>
              The IP address that your browser will pass on to Google Analytics
              will not be associated with any other data stored by Google.
              Through your browser settings, you can block cookies; however,
              this can lead to problems with the use of some functionalities of
              the website. Through the add-on "Google Analytics Opt-out “you can
              through your current web browser opt out to the use of Google
              Analytics from then on:
              http://tools.google.com/dlpage/gaoptout?hl=en. More information
              can be found here:
              https://support.google.com/analytics/answer/6004245
            </p>
            <h1>F. MINORS </h1>
            <p>
              The Sites and their contents are not targeted to minors (those
              under the age of 18) and we do not intend to sell any of our
              products or services to minors. If a minor has provided us with
              Personal Data without parental or guardian consent, the parent or
              guardian should contact
              DataProtection.Marketplase.tradersofafrica.com (for AliExpress.com
              users) or DataProtection@service.marketplase.tradersofafric.com
              (for Marketplase.tradersofafrica.com users) to remove the
              information.
            </p>
            <h1>G. SECURITY MEASURES </h1>
            <p>
              We employ commercially reasonable security methods to prevent
              unauthorized access to the Sites, to maintain data accuracy and to
              ensure the correct use of the information we hold.
            </p>
            <p>
              For registered users of the Sites, some of your information can be
              viewed and edited through your account, which is protected by a
              password. We recommend that you do not divulge your password to
              anyone. Our personnel will never ask you for your password in an
              unsolicited phone call or in an unsolicited email. If you share a
              computer with others, you should not choose to save your log-in
              information (e.g., user ID and password) on that shared computer.
              Remember to sign out of your account and close your browser window
              when you have finished your session.
            </p>
            <p>
              No data transmission over the internet or any wireless network can
              be guaranteed to be perfectly secure. As a result, while we try to
              protect the information we hold for you, we cannot guarantee the
              security of any information you transmit to us and you do so at
              your own risk.
            </p>
            <h1>H. CHANGES TO THIS PRIVACY POLICY </h1>
            <p>
              We may update this Privacy Policy from time to time in response to
              changing legal, technical or business developments. When we update
              our Privacy Policy, we will take appropriate measures to inform
              you, consistent with the significance of the changes we make. We
              will obtain your consent to any material Privacy Policy changes if
              and where this is required by applicable data protection laws. Any
              changes to this Privacy Policy will be communicated by us posting
              an amended Privacy Policy on the Platform. Once posted on the
              Platform, the new Privacy Policy will be effective immediately.
              You can see when this Privacy Notice was last updated by checking
              the “last updated” date displayed at the top of this Privacy
              Notice.
            </p>
            <h1>I. VISITORS FROM THE EUROPEAN UNION</h1>
            <p>
              If you use our Platform or services covered by this Privacy Policy
              from a country that is a Member State of the European Union or
              from the United Kingdom, the data controller of your personal
              information is Marketplase.tradersofafrica.com Nigeria Private
              Limited -Company (incorporated In Nigeria with Company Reg. No
              ------------) For contact details, please see section M. "HOW TO
              CONTACT US" below.
            </p>
            <h1>J. LEGAL BASIS FOR DATA PROCESSING</h1>
            <p>
              We process personal information for the purposes set out in this
              Privacy Policy, as described above. Our legal basis for processing
              personal information will depend on the personal information
              concerned and the specific context in which we collect it.
              However, we will normally collect and process personal information
              about you only where it is:
            </p>
            <ul className="list___items">
              <li>
                Necessary for providing the services pursuant to the agreement
                between you and Marketplase.tradersofafrica.com Nigeria Private
                Limited Company and/or its affiliates (for example, to provide
                you with the services you request and to identify and
                authenticate you so you may use the Platform
              </li>
              <li>
                Necessary to comply with legal requirements (for example, to
                comply with applicable accounting rules and to make mandatory
                disclosures to law enforcement);
              </li>
              <li>
                Necessary for our legitimate interests and not overridden by
                your rights; and/or
              </li>
              <li>Where it is based on your consent. </li>
            </ul>
            <p>
              If we collect and use your personal information in reliance on our
              legitimate interests (or those of any third party), this interest
              will normally be to operate our Platform and services, manage our
              relationship with you and communicate with you as necessary to
              provide our services to you and for our legitimate commercial
              interest, for instance, when responding to your queries, improving
              our Platform and our services, undertaking marketing, or for the
              purposes of ensuring the security of our Platform and services and
              detecting or preventing illegal activities such as fraud. We may
              have other legitimate interests and if appropriate we will make
              clear to you at the relevant time what those legitimate interests
              are.
            </p>
            <p>
              If we ask you to provide personal information to comply with a
              legal requirement or to enter into a contract with you, we will
              make this clear at the relevant time. We will advise you whether
              the provision of your personal information is mandatory or not (as
              well as of the possible consequences if you do not provide your
              personal information). In some instances, you may be required to
              provide us with personal information for processing as described
              above, in order for us to be able to provide you all of our
              services, and for you to use all the features of our Platform.
            </p>
            <p>
              If you have questions about or need further information concerning
              the legal basis on which we collect and use your personal
              information, please contact us using the contact details provided
              under section M. "HOW TO CONTACT US" below.
            </p>
            <h1>K. INTERNATIONAL TRANSFERS OF PERSONAL INFORMATION </h1>
            <p>
              In connection with our provision of the Platform and its connected
              services, we may transfer your personal information to countries
              outside of your home country. We take appropriate steps to ensure
              that recipients of your personal information are bound to duties
              of confidentiality and we implement appropriate measures to ensure
              your personal information will remain protected in accordance with
              this Privacy Policy, such as standard contractual clauses. A copy
              of those clauses can be requested from
              DataProtection@service.Marketplase.tradersofafrica.com.
            </p>
            <p>
              In the event where you are a Buyer and have access to personal
              information belonging to users from China on the Platform, you
              shall comply with the Marketplase.tradersofafrica.com Website
              Users Data Processing Terms.
            </p>
            <h1>L. YOUR RIGHTS</h1>
            <p>
              As a user of the Platform, you have the following data protection
              rights, which you can exercise at any time by contacting us using
              the contact details provided under section M. "HOW TO CONTACT US"
              below:
            </p>
            <ul className="list___items">
              <li>
                The right to access, correct, update or request deletion of your
                personal information.
              </li>
              <li>
                The right to object to processing of your personal information
                when it is based on our legitimate interests, and separately the
                right to object to direct marketing.
              </li>
              <li>
                The right to ask us, in some situations, to restrict processing
                of your personal information or request portability of your
                personal information.
              </li>
              <li>
                The right to opt-out of marketing communications we send you at
                any time. You can exercise this right by clicking on the
                “unsubscribe” or “opt-out””link in the marketing e-mails we send
                you. To opt-out of other forms of marketing (such as postal
                marketing or telemarketing), then please contactus using the
                contact details provided under section M. "HOW TO CONTACT US"
                below.
              </li>
              <li>
                If we have collected and process your personal information with
                your consent, then you have the right to withdraw your consent
                at any time. Withdrawing your consent will not affect the
                lawfulness of any processing we conducted prior to your
                withdrawal, nor will it affect processing of yourpersonal
                information conducted in reliance on lawful processing grounds
                other than consent.
              </li>
              <li>
                The right to complain to a data protection authority about our
                collectionand use of your personal information. For more
                information, please contact your local data protection
                authority.
              </li>
            </ul>
            <p>
              We respond to all requests we receive from individuals wishing to
              exercise their data protection rights in accordance with
              applicable data protection laws.
            </p>
            <p>
              If you are aware of changes or inaccuracies in your information,
              you should inform us of such changes so that our records may be
              updated or corrected.
            </p>
            <h1>M. HOW TO CONTACT US </h1>
            <p>
              You can click here to exercise your rights, and If you have any
              requests, questions or concerns about our use of your personal
              information and this Privacy Policy, please contact us at
              DataProtection@service.tradersofafrica.com.
            </p>
            <h1>N. LANGUAGE </h1>
            <p>
              If there is any conflict between the English version and another
              language version of this Privacy Policy, the English version shall
              prevail.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
