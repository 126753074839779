import React, { useState, useEffect, useMemo, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SellersSidebar from "../SellersComponent/SellerSideBar.jsx";

import { axiosInstance } from "../../../../components/baseUrl.jsx";
import { ProtectedRoutes } from "../../../../components/utils/ProtectedRoutes";
import { GlobalContext } from "../../../../components/utils/GlobalState";

import SearchInput from "../../BuyersDashboard/BuyersComponent/SearchInput";
import PaginationComponent from "../../BuyersDashboard/BuyersComponent/Pagination";
import SellersHeader from "../SellersComponent/SellersHeader.jsx";
import TableSkeleton from "../../../../components/SkeletonLoader/TableSkeleton.js";
import cookies from "js-cookie";
import { Capitalize } from "../../../../components/helpers/functionHelpers.js";
import { CurrencyExchangeContext } from "../../../../components/utils/CurrencyExchangeState.js";

const SellersProducts = () => {
  const { t } = useTranslation();
  const [allUserProduct, setAllUserProduct] = useState([]);
  const { convertCurrency } = useContext(CurrencyExchangeContext);

  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 10;
  const [noMatch, setNoMatch] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { user, userLoading } = useContext(GlobalContext);
  const navigate = useNavigate();

  const selectedCurrency = cookies.get("currency") || "NGN";

  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  useEffect(() => {
    axiosInstance
      .get(`/product/seller/${user?.id}`)
      .then((response) => {
        setAllUserProduct(response.data.data.products);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        if (error.message && error.message === "Network Error") {
          navigate("/no-connection");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const productsData = useMemo(() => {
    let computedProducts = allUserProduct;

    if (search) {
      computedProducts = computedProducts.filter(
        (product) =>
          product.countryOfOrigin
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          product.productName.toLowerCase().includes(search.toLowerCase()) ||
          product.productStatus.toLowerCase().includes(search.toLowerCase())
      );
      if (computedProducts.length < 1) {
        setNoMatch(true);
        setTotalItems(0);
      } else if (computedProducts.length > 0) {
        setNoMatch(false);
      }
    } else {
      setNoMatch(false);
    }

    setTotalItems(computedProducts.length);

    return computedProducts.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [allUserProduct, currentPage, search]);

  return (
    <>
      <div>
        <div className="grid-container">
          <SellersHeader
            user={user}
            handleClick={handleClick}
            title="My Products"
            userLoading={userLoading}
          />

          <SellersSidebar isActive={isActive} handleClick={handleClick} />

          <main className="main">
            <div className="product-listing">
              <div></div>
              <Link to="/create-new-product">
                <button className="btn btn-danger" variant="primary" size="lg">
                  {t("Create_New_Product")}
                </button>
              </Link>
            </div>
            <div className="section-title-ctn">
              <div className="header__search">
                <SearchInput
                  onSearch={(value) => {
                    setSearch(value);
                    setCurrentPage(1);
                  }}
                  placeholder={t("Search_for_products")}
                />
              </div>
            </div>
            {loading ? (
              <TableSkeleton />
            ) : (
              <>
                {allUserProduct && allUserProduct.length > 0 ? (
                  <div className="main-overview">
                    <div className="overview-card no-padding">
                      <div className="table-responsive">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">{t("Product_Info")}</th>
                              <th scope="col">{t("Capacity")}</th>
                              <th scope="col">{t("Price")}</th>
                              <th scope="col">{t("Status")}</th>
                              <th scope="col">{t("Action")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {productsData &&
                              productsData.map((product, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className="d-flex">
                                      <div className="flex-shrink-0">
                                        <img
                                          className="table-product-img"
                                          src={
                                            product.productImages[0] &&
                                            product.productImages[0].image
                                          }
                                          alt="..."
                                        />
                                      </div>
                                      <div className="flex-grow-1 ms-3">
                                        <p>
                                          {product &&
                                            Capitalize(product.productName)}
                                        </p>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {product && product.supplyCapacity}{" "}
                                    {product && product.unitForSupplyCapacity}
                                  </td>
                                  <td>
                                    {selectedCurrency}{" "}
                                    {product &&
                                      convertCurrency(
                                        product.minPricePerUnit,
                                        product?.currencyType,
                                        selectedCurrency
                                      )}
                                  </td>
                                  <td>
                                    {product.productStatus === "PENDING" && (
                                      <div className="text-warning ">
                                        {t("Pending")}
                                      </div>
                                    )}

                                    {product.productStatus === "APPROVED" && (
                                      <div className="text-success">
                                        {t("Approved")}
                                      </div>
                                    )}
                                    {product.productStatus ===
                                      "DISAPPROVED" && (
                                      <div className="text-danger">
                                        {t("Disapproved")}
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <Link
                                      to={`/seller/product-info/${product.id}`}
                                    >
                                      {t("View")}
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="empty-state">
                    <h3>{t("There_are_no_products_created")}</h3>
                    <p>
                      {t("Create_your_first_product")}{" "}
                      <Link to="/create-new-product">{t("here.")}</Link>{" "}
                      {t("All_your_products")}
                    </p>
                  </div>
                )}
                {noMatch === true ? (
                  <div className="empty-state">
                    <h4>{t("No_results_found")}</h4>
                    <p>{t("No_order_matched")}</p>
                  </div>
                ) : (
                  <PaginationComponent
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                )}
              </>
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutes(SellersProducts, ["SELLER"]);
