import { useContext, useState } from "react";
import { useSearchParams } from "react-router-dom";

import toast from "react-hot-toast";
import cookies from "js-cookie";

import { axiosInstance } from "../baseUrl";
import { useEventTag } from "./useEventTag";
import { GlobalContext } from "../utils/GlobalState";
import { AliexpressCartContext } from "../utils/AliexpressCartState";
import {
  addTwoPercent,
  numberWithCommas,
  removeFirstPlus,
  separateCountryCode,
  calculateTwoPercent,
  sumTwoNumbers,
  toastOptions,
} from "../helpers/functionHelpers";
import { useMoMoPayment } from "./useMoMoPayment";
import { CurrencyExchangeContext } from "../utils/CurrencyExchangeState";

export const useCreateOrder = () => {
  const selectedCurrency = cookies.get("currency") || "NGN";

  const [searchParams] = useSearchParams();
  const logisticsStatus = searchParams.get("status") || "";

  const { user, cartItems, totalCartQuantity } = useContext(GlobalContext);
  const { convertCurrency } = useContext(CurrencyExchangeContext);
  const { cart, totalShippingFee } = useContext(AliexpressCartContext);

  const {
    loadMoMo,
    handleMoMoPayment,
    handleCancelMoMoPaymentModal,
    openMoMoPaymentModal,
    setOpenMoMoPaymentModal,
    formatCountdown,
    handleConfirmMoMoPayment,
    isCodeStep,
    countdown,
    confirmLoadMoMo,
  } = useMoMoPayment();

  const { handleEventTag } = useEventTag();

  const [loading, setLoading] = useState(false);
  const [selectedCurrenci, setSelectedCurrenci] = useState("");
  const [paymentTerm, setPaymentTerm] = useState("MoMo");
  const [momoPaymentDetails, setMomoPaymentDetails] = useState({
    phoneNumber: "",
    referralCode: "",
    note: "",
  });

  const [userDetails, setUserDetails] = useState({
    port: "",
    incoterm: "",
    shippingType: "",
  });

  const currentSelectedCurrency = selectedCurrenci || selectedCurrency;

  const handleMoMoPaymentDetailsChange = (e) => {
    const { name, value } = e.target;
    setMomoPaymentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const errors = [
    { condition: !user?.address, message: "Please enter delivery address." },
    {
      condition:
        !userDetails.shippingType || userDetails.shippingType === "Select",
      message: "Please select mode of delivery.",
    },
    {
      condition: !userDetails.incoterm || userDetails.incoterm === "Select",
      message: "Please select incoterm.",
    },
    {
      condition: userDetails.incoterm !== "LOCAL_DELIVERY" && !userDetails.port,
      message: "Please enter port.",
    },
  ];

  // Total cost for just aliexpress products
  const totalCost = cart.reduce((sum, item) => {
    const cost = parseFloat(item.cost);
    const quantity = item.quantity;
    const total = sum + cost * quantity;
    return total;
  }, 0);

  //aliexpress shipping fee
  const aliexpressShippingFee = convertCurrency(
    totalShippingFee,
    "USD",
    currentSelectedCurrency
  );

  const aliexpressTotalItemCostAndHandlingFee = convertCurrency(
    addTwoPercent(totalCost),
    cart[0]?.currency,
    currentSelectedCurrency
  );

  const aliexpressTotalCostafterConvertToSelectedCurrency = sumTwoNumbers(
    aliexpressTotalItemCostAndHandlingFee,
    aliexpressShippingFee
  );

  // total cost for just tofa products without the commas
  function removeCommasAndConvert() {
    if (!cartItems?.cartPrice) {
      return 0;
    }
    if (cartItems?.cartPrice) {
      const tofaTotalCost = convertCurrency(
        cartItems?.cartPrice,
        "USD",
        currentSelectedCurrency
      );
      let noCommas = tofaTotalCost?.replace(/,/g, "");
      return Number(noCommas);
    }
  }

  // total display cost when cart has both aliexpress products and tofa products or just aliexpress product or just tofas products.
  const grandTotal = () => {
    if (totalCartQuantity > 0 && cart.length > 0) {
      return sumTwoNumbers(
        removeCommasAndConvert(),
        aliexpressTotalCostafterConvertToSelectedCurrency
      );
    } else if (totalCartQuantity > 0 && cart.length < 1) {
      return removeCommasAndConvert();
    } else if (totalCartQuantity < 1 && cart.length > 0) {
      return aliexpressTotalCostafterConvertToSelectedCurrency;
    } else return "0.00";
  };

  // handling momo payment
  const handleSubmitMoMoPayment = () => {
    const momoPayload = {
      channel: "nigeria_momo_collection",
      amount: grandTotal(),
      currency: currentSelectedCurrency,
      time_expire: "300",
      channel_extra: {
        payer: {
          partyId: removeFirstPlus(user?.phoneNumber),
          partyIdType: "MSISDN",
        },
        payeeNote: momoPaymentDetails.note,
        payerMessage: "Thank you for buying from Traders of Africa marketplace",
      },
    };
    handleMoMoPayment(momoPayload);
  };

  // modifying aliexpress products array to match what's expected in the payload
  const modifiedCart = cart?.map((productDetails) => {
    const { quantity, productId, sku_attr } = productDetails;
    return {
      logistics_service_name: "CAINIAO_FULFILLMENT_STD",
      sku_attr: sku_attr,
      product_count: quantity,
      product_id: productId,
    };
  });

  // modifying tofa products array to match what's expected in the payload
  const modifiedCartItems =
    cartItems?.cart &&
    cartItems?.cart?.map((obj) => {
      const { productId, quantity, product, totalPrice } = obj;
      return {
        productID: productId,
        sellerId: product?.userId,
        cost: convertCurrency(totalPrice, "USD", currentSelectedCurrency),
        quantityOrdered: quantity?.toString(),
        productName: product?.productName,
        productDescription: product?.productDescription,
        countryOfOrigin: product?.countryOfOrigin || "Nigeria",
        logisticsStatus: logisticsStatus === "successful" ? "PAID" : "UNPAID",
        currencyType: currentSelectedCurrency,
      };
    });

  //invoice for aliexpress
  const aliexpressInvoice = cart?.map((productDetails) => {
    return {
      name: productDetails?.productName,
      image: productDetails?.imgUrl,
      quantity: productDetails?.quantity,
      price: numberWithCommas(
        convertCurrency(
          productDetails?.cost,
          productDetails?.currency,
          currentSelectedCurrency
        )
      ),
    };
  });

  // invoice object for tofa
  const tofaInvoice = cartItems?.cart?.map((productDetails) => {
    return {
      name: productDetails?.product?.productName,
      image: productDetails?.product?.productImages[0]?.image,
      quantity: productDetails?.quantity,
      price: convertCurrency(
        productDetails?.totalPrice,
        "USD",
        currentSelectedCurrency
      ),
    };
  });

  // verifying user address
  const getLocationDetails = async (address) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          address
        )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );

      const data = await response.json();

      if (data.status !== "OK") {
        toast.error(
          `Invalid address. Please enter a valid address from the suggestions.`,
          toastOptions
        );
        return;
      }

      const addressComponents = data.results[0].address_components;
      const locationDetails = {
        streetNo: "",
        home: "",
        street: "",
        city: "",
        state: "",
        country: "",
        countyShort: "",
        zipCode: "",
      };

      addressComponents.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number")) {
          locationDetails.streetNo = component.long_name;
        }
        if (types.includes("route")) {
          locationDetails.home = component.long_name;
        }
        if (types.includes("neighborhood")) {
          locationDetails.street = component.long_name;
        }
        if (types.includes("administrative_area_level_2")) {
          locationDetails.city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          locationDetails.state = component.long_name;
        }
        if (types.includes("country")) {
          locationDetails.country = component.long_name;
        }
        if (types.includes("country")) {
          locationDetails.countyShort = component.short_name;
        }
        if (types.includes("postal_code")) {
          locationDetails.zipCode = component.long_name;
        }
      });

      return locationDetails;
    } catch (error) {
      toast.error(
        `Invalid address. Please enter a valid address from the suggestions.`,
        toastOptions
      );
    }
  };

  // creating order
  const handleSubmit = async (address) => {
    if (totalCartQuantity < 1 && cart.length < 1) {
      return;
    }

    if (!address) {
      toast.error(`Please enter an address.`, toastOptions);
      return;
    }

    await getLocationDetails(address);

    const error = errors.find((error) => error.condition);
    if (totalCartQuantity > 0 && error) {
      toast.error(error.message, toastOptions);
      return;
    }

    if (!paymentTerm || paymentTerm === "Select payment method") {
      toast.error("Please select payment method.", toastOptions);
      return;
    }

    let promises = [];

    setLoading(true);

    // payload for tofa's order
    if (totalCartQuantity > 0) {
      const tofaOrderPayload = {
        bulkOrder: modifiedCartItems,
        destination: user?.address,
        port:
          userDetails.incoterm === "LOCAL_DELIVERY" ? "None" : userDetails.port,
        note: "None",
        incoterm: userDetails.incoterm,
        shippingType: userDetails.shippingType,
        paymentTerm: paymentTerm === "MoMo" ? "PAYNOW" : paymentTerm,
      };

      const tofaPromise = axiosInstance.post(
        "/order/bulk-order",
        tofaOrderPayload
      );

      promises.push(tofaPromise);
    }

    // payload for aliexpress order
    if (cart.length > 0) {
      const locationDetails = await getLocationDetails(address);

      const { streetNo, home, street, city, state, countyShort, zipCode } =
        locationDetails;

      const { countryCode, mainNumber } = separateCountryCode(
        user?.phoneNumber
      );

      const aliexpressOrderPayload = {
        dsExtendRequest: {
          payment: {
            pay_currency: "NGN",
          },
        },
        paramPlaceOrderRequest4OpenAPIDTO: {
          product_items: modifiedCart,
          logistics_address: {
            full_name: `${user?.firstName + " " + user?.LastName}`,
            contact_person: `${user?.firstName + " " + user?.LastName}`,
            address: `${streetNo + " " + home + " " + street}`,
            address2: user?.address,
            city: city,
            province: `${state + " state"}`,
            country: countyShort,
            zip: zipCode,
            phone_country: countryCode,
            mobile_no: mainNumber,
            locale: "en_US",
          },
        },
      };

      const aliexpressPromise = axiosInstance.post(
        "/ali-express/order/create",
        aliexpressOrderPayload
      );
      promises.push(aliexpressPromise);
    }

    try {
      const [data, data1] = await Promise.all(promises);
      if (
        totalCartQuantity > 0 &&
        cart.length > 0 &&
        data1?.data?.aliexpress_ds_order_create_response?.result?.is_success ===
          false
      ) {
        if (data1?.data?.aliexpress_ds_order_create_response.result.error_msg) {
          toast.error(
            data1?.data?.aliexpress_ds_order_create_response.result.error_msg,
            toastOptions
          );
          return;
        } else if (
          data1?.data?.aliexpress_ds_order_create_response?.result
            ?.error_code === "DELIVERY_METHOD_NOT_EXIST"
        ) {
          toast.error(
            " DELIVERY FOR THIS PRODUCT NOT AVAILABLE TO YOUR ADDRESS",
            toastOptions
          );
          return;
        } else {
          toast.error("PRODUCT OUT OF STOCK", toastOptions);
        }
      } else if (
        totalCartQuantity < 1 &&
        cart.length > 0 &&
        data?.data?.aliexpress_ds_order_create_response?.result?.is_success ===
          false
      ) {
        if (data?.data?.aliexpress_ds_order_create_response.result.error_msg) {
          toast.error(
            data?.data?.aliexpress_ds_order_create_response.result.error_msg,
            toastOptions
          );
          return;
        } else if (
          data?.data?.aliexpress_ds_order_create_response?.result
            ?.error_code === "DELIVERY_METHOD_NOT_EXIST"
        ) {
          toast.error(
            " DELIVERY FOR THIS PRODUCT NOT AVAILABLE TO YOUR ADDRESS",
            toastOptions
          );
          return;
        } else {
          toast.error("PRODUCT OUT OF STOCK", toastOptions);
        }
      }

      if (totalCartQuantity > 0 && data) {
        localStorage.setItem(
          "orderIds",
          JSON.stringify(data.data.data.orderIDs)
        );
      }

      // aliexpress order id
      const aliexpressOrderId = () => {
        if (
          totalCartQuantity > 0 &&
          cart.length > 0 &&
          data1?.data?.aliexpress_ds_order_create_response?.result?.order_list
            ?.number
        ) {
          return data1?.data?.aliexpress_ds_order_create_response?.result
            ?.order_list?.number;
        } else if (
          totalCartQuantity < 1 &&
          cart.length > 0 &&
          data?.data?.aliexpress_ds_order_create_response?.result?.order_list
            ?.number
        ) {
          return data?.data?.aliexpress_ds_order_create_response?.result
            ?.order_list?.number;
        }
      };

      // invoice details to be sent as email
      const invoiceDetails = {
        userEmail: user?.email,
        userPhoneNumber: user?.phoneNumber,
        userName: `${user?.firstName} ${user?.LastName}`,
        userAddress: user?.email,
        grandTotal: `${currentSelectedCurrency} ${numberWithCommas(
          grandTotal()
        )}`,
        shippingFee: `${currentSelectedCurrency} ${numberWithCommas(
          aliexpressShippingFee
        )}`,
        handlingFee: `${currentSelectedCurrency} ${numberWithCommas(
          convertCurrency(
            calculateTwoPercent(totalCost),
            cart[0]?.currency,
            currentSelectedCurrency
          )
        )}`,
        tax: `${currentSelectedCurrency} 0.00`,

        orderDetails: {
          orderIds: aliexpressOrderId(),
          products: aliexpressInvoice,
          totalAmount: numberWithCommas(
            aliexpressTotalCostafterConvertToSelectedCurrency
          ),
        },
        orderDetails2: {
          products2: tofaInvoice,
          totalAmount2: numberWithCommas(removeCommasAndConvert()),
        },
      };
      localStorage.setItem("invoiceDetails", JSON.stringify(invoiceDetails));
      // handleSendInvoice(invoiceDetails);

      // check if the payment term matches the specified term
      const isPaymentTerm = (paymentTerm, term) => paymentTerm === term;

      const isPaymentForBothAliexpressAndTofa = (
        data,
        data1,
        paymentTerm,
        term
      ) => isPaymentTerm(paymentTerm, term) && data && data1;

      const isJustAliexpressProduct = (
        totalCartQuantity,
        cart,
        data,
        paymentTerm,
        term
      ) =>
        totalCartQuantity < 1 &&
        cart.length > 0 &&
        isPaymentTerm(paymentTerm, term) &&
        data;

      const isJustTofasProduct = (
        totalCartQuantity,
        cart,
        data,
        paymentTerm,
        term
      ) =>
        totalCartQuantity > 0 &&
        cart.length < 1 &&
        isPaymentTerm(paymentTerm, term) &&
        data;

      const checkPaymentConditions = (
        data,
        data1,
        totalCartQuantity,
        cart,
        paymentTerm,
        term
      ) => {
        return (
          isPaymentForBothAliexpressAndTofa(data, data1, paymentTerm, term) ||
          isJustAliexpressProduct(
            totalCartQuantity,
            cart,
            data,
            paymentTerm,
            term
          ) ||
          isJustTofasProduct(totalCartQuantity, cart, data, paymentTerm, term)
        );
      };

      // Checking if user selected MoMo as payment option
      if (
        checkPaymentConditions(
          data,
          data1,
          totalCartQuantity,
          cart,
          paymentTerm,
          "MoMo"
        )
      ) {
        setOpenMoMoPaymentModal(true);
      }
    } catch (error) {
      console.log("error placing order", error);

      if (error?.aliexpress_ds_order_create_response?.result?.error_msg) {
        toast.error(
          error.aliexpress_ds_order_create_response.result.error_msg,
          toastOptions
        );
        handleEventTag(`e_ali_checkout_${error}`, user.id);
      } else if (error?.response?.data?.errors[0].message) {
        toast.error(error?.response?.data?.errors[0].message, toastOptions);
        handleEventTag(
          `e_checkout_${error.response.data.errors[0].message}`,
          user.id
        );
      } else {
        handleEventTag(`e_checkout`, user.id);
        // toast.error(`Network error!`, toastOptions);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    handleSubmit,
    loading,
    setSelectedCurrenci,
    setPaymentTerm,
    setUserDetails,
    userDetails,
    currentSelectedCurrency,
    selectedCurrenci,
    totalCartQuantity,
    grandTotal,
    totalCost,
    loadMoMo,
    handleCancelMoMoPaymentModal,
    openMoMoPaymentModal,
    handleSubmitMoMoPayment,
    handleMoMoPaymentDetailsChange,
    momoPaymentDetails,
    formatCountdown,
    handleConfirmMoMoPayment,
    isCodeStep,
    countdown,
    confirmLoadMoMo,
  };
};
