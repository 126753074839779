/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";

import toast from "react-hot-toast";

import { axiosInstance } from "../baseUrl";

import { toastOptions } from "../helpers/functionHelpers";
import { useCreateMoMoFootSoldierOrder } from "./useCreateMoMoFootSoldierOrder";
import { AliexpressCartContext } from "../utils/AliexpressCartState";
import { useDeleteBulkProductCart } from "./useDeleteBulkProductCart";
import { GlobalContext } from "../utils/GlobalState";
import { useSendOrderInvoiceAsEmail } from "./useSendOrderInvoiceAsEmail";
import { useUpdateOrderStatus } from "./useUpdateOrderStatus";

export const useMoMoPayment = () => {
  const { updateOrderStatus } = useUpdateOrderStatus();
  const { handleCreateMoMoFootSoldierOrder } = useCreateMoMoFootSoldierOrder();
  const { handleSendInvoice } = useSendOrderInvoiceAsEmail();
  const { handleDelete } = useDeleteBulkProductCart();
  const { cart, setRerenderCart } = useContext(AliexpressCartContext);
  const { cartItems } = useContext(GlobalContext);
  const [loadMoMo, setLoadMoMo] = useState(false);
  const [confirmLoadMoMo, setConfrimLoadMoMo] = useState(false);
  const [openMoMoPaymentModal, setOpenMoMoPaymentModal] = useState(false);
  const [countdown, setCountdown] = useState(300);
  const [isCodeStep, setIsCodeStep] = useState(false);
  const [merchantId, setMerchantId] = useState("");
  const countdownRef = useRef(null);

  const handleCancelMoMoPaymentModal = () => {
    setOpenMoMoPaymentModal(false);
  };

  //referral products for momo foot soldiers
  const modifiedReferalProducts = cart?.map((productDetails) => {
    const { quantity, cost, productName } = productDetails;
    return {
      productName,
      price: JSON.stringify(cost * quantity),
      quantity,
    };
  });

  useEffect(() => {
    if (isCodeStep) {
      countdownRef.current = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }

    return () => clearInterval(countdownRef.current);
  }, [isCodeStep]);

  useEffect(() => {
    if (countdown <= 0) {
      handleConfirmMoMoPayment();
    }
  }, [countdown]);

  const handleMoMoPayment = async (payload) => {
    setLoadMoMo(true);
    try {
      const data = await axiosInstance.post(`/ipg/charge/create`, payload);
      if (data?.data?.error_type || data?.data?.error_msg) {
        setIsCodeStep(false);
        setLoadMoMo(false);
        if (data?.data?.error_type === "authentication_error") {
          toast.error("Please enter a valid MoMo phone number", toastOptions);
          return;
        } else {
          toast.error(`${data?.data?.error_msg}`, toastOptions);
          return;
        }
      }
      setMerchantId(data?.data?.biz_content?.merch_order_id);
      setLoadMoMo(false);
      setIsCodeStep(true);
    } catch (error) {
      console.log("error", error);
      setLoadMoMo(false);
    }
  };

  const handleConfirmMoMoPayment = async (refererCode) => {
    if (!merchantId) {
      return;
    }
    const referralOrderPayload = {
      products: modifiedReferalProducts,
      companyName: "Aliexpress",
      refererCode: refererCode,
    };
    setConfrimLoadMoMo(true);
    try {
      clearInterval(countdownRef.current);
      const data = await axiosInstance.post(`/ipg/charge/query`, {
        merch_order_id: merchantId,
      });
      setConfrimLoadMoMo(false);

      if (data?.data?.biz_content?.paid === true) {
        toast.success(
          `Payment successful, your order has been confirmed.`,
          toastOptions
        );
        const storedInvoiceDetails = localStorage.getItem("invoiceDetails");
        const invoiceDetails = JSON.parse(storedInvoiceDetails);
        const savedOrderIds = localStorage.getItem("orderIds");
        const tofaOrderIds = JSON.parse(savedOrderIds);
        if (invoiceDetails) {
          handleSendInvoice(invoiceDetails);
        }
        if (tofaOrderIds) {
          updateOrderStatus(tofaOrderIds);
        }
        localStorage.removeItem("cart");
        setRerenderCart((prev) => !prev);
        handleDelete(cartItems.cart);
        if (modifiedReferalProducts && refererCode) {
          handleCreateMoMoFootSoldierOrder(referralOrderPayload);
        }
        handleCancelMoMoPaymentModal();
      } else if (data?.data?.biz_content?.paid === false && countdown <= 0) {
        toast.error(`Payment unsuccessful, Order cancelled!`, toastOptions);
        handleCancelMoMoPaymentModal();
      } else {
        toast.error(
          `Payment unsuccessful, confirm payment on your MoMo app and proceed`,
          toastOptions
        );
      }
    } catch (error) {
      setConfrimLoadMoMo(false);
      console.log("error", error);
    }
  };

  const formatCountdown = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  return {
    loadMoMo,
    handleMoMoPayment,
    handleCancelMoMoPaymentModal,
    openMoMoPaymentModal,
    setOpenMoMoPaymentModal,
    formatCountdown,
    handleConfirmMoMoPayment,
    isCodeStep,
    countdown,
    confirmLoadMoMo,
  };
};
